@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#IEpage .secondary-font-color {
  color: #1788c7!important;
}
#IEpage .font-bold {
  font-weight: 600;
}
#IEpage #logo-wrap{
  margin: 40px 0px 35px;
}
#IEpage #content-wrap{
  background-color: #f3f6f7;
  height: 100%;
}
#IEpage #info-wrap{
  text-align: center;
  padding: 40px 0px;
}
#IEpage #info-wrap p{
  font-size: 16px;
}
#IEpage #compatible-browser-wrap{
  text-align: center;
}
#IEpage #compatible-browser-wrap .browser-type{
  width: 230px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
}
#IEpage #compatible-browser-wrap .browser-type .browser-logo, #IEpage #compatible-browser-wrap .browser-type .browser-name{
  display: block;
}
#IEpage #compatible-browser-wrap .browser-type .browser-logo{
  margin: auto;
}
#IEpage #compatible-browser-wrap .browser-type .browser-name{
  text-align: center;
}
#IEpage #specs-info-wrap{
  margin: 50px auto;
}
#IEpage #specs-info-wrap .header-wrap {
  background-color: #1788c7!important;
  padding: 10px 20px;
  max-width: 900px;
  margin: auto;
}
#IEpage #specs-info-wrap .header-wrap h5{
  margin: 0;
  color: #fff;
  font-weight: 600;
}
#IEpage #specs-info-wrap .body-wrap {
  max-width: 900px;
  margin: auto;
}
@media (max-width: 1200px){
    #IEpage .container {
      max-width: 1140px;
  }
}

@media (max-width: 992px){
  #IEpage .container {
    max-width: 960px;
  }
}

@media (max-width: 768px){
  #IEpage .container {
    max-width: 720px;
  }
}

@media (max-width: 576px){
  #IEpage .container {
    max-width: 540px;
  }
}
#IEpage .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
#IEpage .column-1, #IEpage .column-2{
  padding: 20px;
  font-size: 14px;
}
#IEpage .column-1 {
  float: left;
  width: 26%;
  background-color: #eff2f3;
}
#IEpage .column-2 {
  float: left;
  width: 74%;
}
#IEpage .column-1, #IEpage .column-2{
  border-bottom: 1px solid #dee1e2;
}
/* Clear floats after the columns */
#IEpage .row:after {
  content: "";
  display: table;
  clear: both;
}
#IEpage .row{
  margin:0!important
}
#dollarSign
{
    width: auto;
    height: 200px;
    left: 9px;
    top: 7px;
    z-index: 4;
    position: absolute;
    color: #495057;
}

.currencyInputsss
{
    padding-left:50px;
}

.button-inblock {
  display: inline-block;
  left: 10px;
  top: -4px;
  position: relative;
}

div#report-list-container tbody tr td:nth-child(7), #modal-for-individual tbody tr td:nth-child(3)
{
  text-align: right;
}
#web-pay-history-table tbody tr td:nth-child(6), #payment-history-complete tbody tr td:nth-child(3){
  text-align: right;
}
#admin-control-login .container {
  position: relative;
  top: 10%;
}

#admin-reports-container .csv-container {
  float: right;
  margin: 20px 0;
}

#admin-reports-container a.btn-export-csv {
  color: #fff;
  background-color: #08c484;
  border: 1px solid #08c484;
  border-radius: 5px;
  font-size: 14px;
  padding: 12px 25px;
  font-weight: 500!important;
}

#admin-reports-container a.btn-export-csv:hover{
    color: #08c484!important;
    background-color: #fff!important;
    border-color: #08c484!important;
}


#admin-reset-pass-container {
  padding: 35px 30px;
}

div#report-list-container thead tr th, div#report-list-container tbody tr td {
  vertical-align: middle;
}
div#step1-cont .check-container {
  margin-top: 15px;
}
#admin-control .nav-tabs a {
  color: black;
}
#admin-control .container-check {
  padding-left: 30px;
  margin-bottom: 10px;
}

.rdw-editor-main {
  height: 200px;
  border: 1px solid #F1F1F1;
  padding: 0px 10px;
}

#admin-control table {
  table-layout: auto!important;
}
@media (max-width: 768px){
  #admin-announcement-container .button-right, #admin-announcement-container .button-left {
    margin-top: 10px;
  }
  #admin-control #container-top {
    padding: 10px 30px;
  }
} 
@media (max-width: 425px){
  #admin-control #container-top {
    padding: 10px 30px;
  }
  #admin-control .nav-link {
    width: 100%!important;
  }
  #admin-add-user-section .view-user-container.col {
    position: relative;
    margin-top: 30px;
    padding: 30px 10px;
  }
  #admin-reports-container .search-container-right {
    margin-left: 0px;
  }
  #admin-phonedin-container .phonedin-search-cont .input-cont-right, #admin-phonedin-container  .phonedin-search-cont .button-cont-right {
    margin-left: 0px;
  }
}
#modal-with-file .select-type label {
  margin-right: 10px;
}
#admin-phonedin-container .subtotal-cont-right {
  text-align: right;
}

#admin-phonedin-container .table-summary-cont p {
  margin-top: 30px;
}

#admin-phonedin-container .add-left img {
  margin-left: 8px;
}

#admin-phonedin-container .defaultInput {
  margin-top: -12px;
}

#admin-phonedin-container .input-cont-right {
  /* max-height: 140px; */
  overflow-y: auto;
}

#admin-phonedin-container .button-cont-right {
  vertical-align: top;
}

#admin-phonedin-container .input-cont-left{
  vertical-align: top;
}

#admin-phonedin-container .input-left-search, #admin-phonedin-container .add-left {
  display: inline-block;
}

#admin-reports-container .total-cont {
  margin-top: 30px;
}

#admin-reports-container .total-left p, #admin-reports-container .total-right p {
  margin-bottom: 0px;
  font-weight: 500;
}

#admin-reports-container .total-left, #admin-reports-container .total-right {
  display: inline-block;
  width: 50%;
}

.total-left label, .total-right label {
  width: 60%;
}


.nav-tabs .nav-link {
  border: 0.5px solid #dee2e6!important;
  border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}

.shopping-button-cont {
  padding-bottom: 60px;
}

.button-shopping-cancel button:hover {
  color: #333!important;
}

.button-shopping-cancel button {
  border: 0px!important;
  background-color: #fff;
  color: #b4b4bd;
}

.table-shopping-cont tr th {
  padding: 10px 25px;
}

.table-shopping-cont tr.with-style {
  background-color: #ECECEC;
  font-weight: 500;
}

.table-shopping-cont td.with-outline {
  border-right: 1px solid #cccccc;
  font-size: 15px;
}

#step2-cont .shopping-button-cont-left {
  float: left;
}

#step2-cont .shopping-button-cont-right {
  float: right;
}

#step2-cont .table-shopping-cont table {
   margin-top: 30px;
   border: 1px solid #dee2e6!important;
}

#step2-cont .table-shopping-cont {
  border-top: 1px solid #dee2e6!important;
  padding-top: 30px;
  margin-top: 30px;
}

#step2-cont .details-button-cont {
  margin-top: 30px;
}

.details-button-cont .button-details-continue, .details-button-cont .button-details-back, .shopping-button-cont .button-shopping-continue, .shopping-button-cont .button-shopping-cancel {
  display: inline-block;
}

.details-summary-cont {
  margin-top: 20px;
}

.details-summary-cont .table td, .table th {
  border-top: 0px solid #dee2e6!important;
  padding: 5px 0px;
}

#modal-for-individual-reports .details-summary-cont .table td, .table th {
  border-top: 0px solid #dee2e6!important;
  padding: 5px 10px;
  font-weight: 600;
}

#modal-for-individual-reports .modal-header , #modal-for-phonedin-payment .modal-header{
  border-bottom: 0px solid #dee2e6!important;
}

#modal-for-individual-reports .modal-body, #modal-for-phonedin-payment .modal-body {
  margin-top: -25px;
}

#modal-for-individual-reports h5{
  border-bottom: 1px solid #dee2e6!important;
  padding-bottom: 20px;
  
}

.table-summary-cont td.with-outline a {
  color: #007bff!important;
  cursor: pointer;
  font-weight: 600;
}

#modal-for-phonedin-payment .react-bootstrap-table table {
  table-layout: auto;
  margin-top: 0px;
}

#modal-for-phonedin-payment table th {
  background-color: #1788C7;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

#modal-for-individual-reports .td-style-first {
  font-weight: 500!important;
}

.phonedin-search-cont .top-cont {
  margin-top: 10px;
}

#modal-for-individual-reports .btn-submit-change-ok, #modal-for-phonedin-payment .btn-submit-change-ok {
  text-align: right;
}

#modal-for-phonedin-payment p {
  font-weight: 500;
}

#modal-for-individual-reports button.btn.btn-change-ok.font-bold.btn-primary, #modal-for-phonedin-payment button.btn.btn-change-ok.font-bold.btn-primary {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 110px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin: 10px 0px;
}

.add-to-cart-cont .button-cart-cancel button:hover, .button-details-back button:hover, .button-shopping-back button:hover {
  color: #fff!important;
  background-color: #dee2e6!important;
  border-color: #dee2e6!important;
}

.button-shopping-back button {
  color: #000;
  background-color: #fff;
  border-color: #dee2e6;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
}

.add-to-cart-cont .button-cart-cancel button, .button-details-back button{
  color: #000;
  background-color: #fff;
  border-color: #dee2e6;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
  margin-left: 10px;
}

.add-to-cart-cont .button-cart-add button, .button-details-continue button, .button-shopping-continue button  {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
}

.add-to-cart-cont .button-cart-add, .add-to-cart-cont .button-cart-cancel {
  display: inline-block;
}

.table-summary-cont tr th{
  padding: 10px 25px;
}

.table-summary-cont table {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.table-summary-cont td.with-outline {
  border-right: 1px solid #cccccc;
  font-size: 15px;
}

.table-summary-cont .with-style {
  background-color: #ECECEC;
  font-weight: 500;
}

#admin-phonedin-container .phonedin-search-cont {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 25px 0px 40px;
}

.phonedin-search-cont .input-cont-right, .phonedin-search-cont .button-cont-right {
  margin-left: 25px;
}

.button-cont-right button.btn.btn-change-ok.font-bold.btn-primary {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 120px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 22px;
}

#admin-phonedin-container .input-cont-left, #admin-phonedin-container .input-cont-right , #admin-phonedin-container .button-cont-right {
  display: inline-block;
}

.phonedin-search-cont select, .phonedin-search-cont input {
  display: block;
  /* width: 100%; */
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

.table-users-container {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

#admin-announcement-container .announce-container {
  padding: 10px;
}

.search-container input , .search-container select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#modal-for-individual .react-bootstrap-table, #modal-for-individualtranslog .react-bootstrap-table{
  padding: 10px;
}

#modal-for-individual .modal-body, #modal-for-individualtranslog .modal-body{
  margin-top: -25px;
}

#modal-for-individual p {
  font-weight: 600;
  margin-bottom: 20px;
}

.search-container .btn-change-ok{
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 120px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
}


#admin-control .container {
  max-width: 1180px;
}

#admin-control .nav-tabs {
  border-bottom: 0px solid #dee2e6; 
}

#admin-announcement-container .item-cont a {
  color: #007bff;
  cursor: pointer;
  font-weight: 600;
}

#admin-control .tab-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 15px;
}

.announcement-login .top-cont img {
  margin: 0 auto;
}

.announcement-login .cont-left h6 {
  text-align: center;
}

#announcement-container .table td, #announcement-container  .table th {
  border-top: 0px solid #dee2e6!important;
  vertical-align: middle;
}

.announcement-login a {
  cursor: pointer;
}

.announce-container a, .click-a {
  cursor: pointer;
}

#modal-announce .modal-body {
  text-align: center;
  margin-top: -25px;
  margin-bottom: 20px;
}

#modal-announce .modal-header {
  border-bottom: 0px solid #dee2e6!important;
}

.announcement-login a iframe {
  width: 200px;
  height: 150px;
  pointer-events: none;
}

.announce-container td a iframe {
  width: 100px;
  height: 80px;
  pointer-events: none;
}

#modal-announce .iframe-cont iframe {
  width: 500px;
  height: 300px;
}

#modal-with-prompt .featured-iframe iframe {
  width: 500px;
  height: 300px;
}
.announcement-login td .top-cont .featured-iframe iframe {
  width: 500px;
  height: 300px;
  text-align: center;
}

.announcement-login td .top-cont .featured-iframe  {
  text-align: center;
}

.iframe-click {
  cursor: pointer;
  background: rgba(0,0,0,0);
  width: 200px;
  height: 150px;
}

#admin-announcement-container .body-cont {
  margin-top: 10px;
}

#system-pop-announcement-container {
  margin-top: -15px;
  padding-bottom: 20px;
}

#system-pop-announcement-container .title-cont h5 {
  text-align: center;
  padding: 20px 0px 10px;
  color: #1788c7!important;
  font-weight: 600;
}

.input-cont label {
  font-weight: 500;
}

#report-list-container {
  margin-top: 20px;
}

#admin-reports-container, #admin-phonedin-container {
  padding: 35px 30px;
}

.search-container .input-cont {
  margin-bottom: 10px;
}

.search-container-right  {
  margin-left: 20px;   
}

.search-container-left, .search-container-right {
  display: inline-block;
}

#report-list-container .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

#report-list-container .btn-secondary, #report-list-container .btn-secondary:hover {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff!important;
  background-color: #1788C7!important;
  border-color: #1788C7!important;
}

.page-item.active .page-link {
  background-color: #1788C7;
  border-color: #1788C7;
}

#admin-control thead, #modal-for-individual thead, #modal-for-individualtranslog thead {
  background-color: #1788C7;
  color: #fff;
}

#modal-for-individual .modal-header, #modal-for-individualtranslog .modal-header {
  border-bottom: 0px solid #dee2e6!important;
}

#modal-for-individual .btn-change-ok , #modal-for-individualtranslog .btn-change-ok{
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 110px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  float: right;
  margin: 10px 0px;
}
#phonedIn-section #step2-cont .checkmark {
  top: -10px;
}
#report-list-container .pagination {
  float: right;
}

#report-list-container td, #modal-for-individual td,#modal-for-individualtranslog td {
  font-size: 14px;
  padding: 8px;
}
#modal-for-individualtranslog .modal-content{
  /* width: 1366px; */
}

#modal-for-individualtranslog{
  margin: 10px;
  max-width: 100%;
}
#modal-for-individualtranslog td{
  text-align: left;
}
#report-list-container .react-bootstrap-table table, #modal-for-individual .react-bootstrap-table table, #modal-for-individualtranslog .react-bootstrap-table table{
  table-layout: auto!important;
}
#report-list-container tbody tr td a {
  color: #007bff;
  cursor: pointer;
  font-weight: 600;
}

#report-list-container .table thead th, #modal-for-individual .table thead th, #modal-for-individualtranslog .table thead th{
  font-size: 13px;
  text-align: center;
}

.logout-cont {
  text-align: right;
  font-size: 14px;
}

#header-drop-admin .dropdown-menu.show {
  top: 40px!important;
  left: 15px!important;
}

#header-drop-admin .dropdown-toggle::before {
  content: none!important;
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
}

.dropdown-menu span , .dropdown-menu span {
  margin-left: 16px;
}

#header-drop-admin .content-left , #header-drop-admin .content-right {
  display: inline-block;
  margin-right: -10px;
}

#modal-for-alert h6{
  margin: -15px 0px 25px;
}

#modal-for-alert .modal-content {
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
}

#modal-for-alert .modal-body {
  text-align: center;
}

#modal-for-alert .btn-submit-change-ok {
  margin: 0px 0px 5px;
}

#modal-for-alert .btn-change-ok {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 110px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}

button:focus, table:focus, th:focus, td:focus {
  outline: none!important;
  box-shadow: none!important;
}

.btn-change-pass:hover, .btn-change-ok:hover{
  color: #08c484!important;
  background-color: #fff!important;
  border-color: #08c484!important;
}

#modal-change-pass .btn-submit-change-pass{
  float: right;
  margin: 20px 0;
}

#modal-change-pass h4 {
  margin-top: -10px;
  margin-bottom: 30px;
}

#modal-change-pass .modal-header, #modal-for-alert .modal-header {
  border-bottom: 0px solid #dee2e6!important;
  padding: 10px;
}

#modal-change-pass .modal-body {
  padding: 0px 40px;
}

#modal-change-pass .modal-content {
  width: 70%;
  margin: 0 auto;
}

#modal-change-pass .btn-change-pass, #modal-for-alert .btn-change-pass {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}

#header-drop-admin .btn-secondary {
  color: #000!important;
  background-color: transparent!important;
  border-color: transparent!important;
}

#header-drop-admin .btn-secondary:hover {
  color: #000!important;
  background-color: transparent!important;
  border-color: transparent!important;
}

#header-drop-admin .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "\2699";
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
  font-size: 25px;
}

.isDisabled-admin {
    background-color: #CBCBCB!important;
    border-color: #CBCBCB!important;
    color: #fff!important;
    pointer-events: none;
}

#announcement-container .slick-list {
  height: 600px!important;
  max-height: 800px;
}

#system-pop-announcement-container .featured-iframe {
  text-align: center;
}

#modal-with-prompt .modal-body {
  padding: 0px 40px;
}

#system-pop-container .syst {
  padding: 0px 0px 20px;
}

#system-pop-container {
  margin-top: -15px;
}

#modal-with-prompt .modal-header {
  border-bottom: 0px solid #dee2e6!important;
}

#system-pop-container .syst h5 {
  color: #1788c7!important;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
}
#system-pop-container .btn-primary {
  background-color: #08C585;
  border-color: #08C585;
  width: 150px;
  margin-top: 20px;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0px;
  width: 20px;
  height: 20px;
  content: '\2022';
  text-align: center;
  opacity: 2.25;
  border: 1px solid #fff;
  border-radius: 63px;
  color: transparent;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 2.25;
}

#announcement-container .cont-left {
  margin-top: 20px;
}

.table td, .table th {
  border-top: 1px solid #dee2e6!important;
}

#announcement-container h6{
  color: #fff;
  /* text-transform: uppercase; */
  font-weight: 600;
}

#announcement-container h5, #announcement-container p {
  color: #fff;
  text-align: left;
}

#announcement-container button.slick-arrow {
  display: none!important;
}
#announcement-container .with-announcement {
  border-bottom: 1px solid #fff;
  margin-bottom: 40px
}
#announcement-container {
  padding: 50px;
}
.disabled-front {
  background-color: #CBCBCB!important;
  border-color: #CBCBCB!important;
  color: #fff!important;
  pointer-events: none;
}
#modal-with-file .modal-header {
  border-bottom: 0px solid #dee2e6!important;
  padding: 10px;
}
#modal-with-file h4 {
  padding-bottom: 20px;
}
p.no-data {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
button.btn.btn-announcement-disabled {
  color: #fff;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
  width: 230px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  pointer-events: none;
}
#modal-with-file .custom-file-label{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#tabs-containers {
  margin-top: 30px;
}
#modal-with-file .btn-submit-with-file button {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}
#modal-with-file .btn-submit-with-file {
  float: right;
  margin: 20px 0;
}
#modal-with-file .modal-content {
  width: 80%;
  margin: 0 auto;
}
#modal-with-file .modal-body {
  padding: 0px 40px;
}
#custom-upload .custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  padding: 6px 25px;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #666666;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
#admin-announcement-container i.fa.fa-plus, #admin-announcement-container i.fa.fa-trash{
  float: left;
  position: relative;
  left: 25px;
  top: 3px;
}
.users-table img {
  cursor: pointer;
}
#error-container {
  background-color: #EF5350;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  display: none;
}

#error-container h6 {
  padding: 10px;
  font-size: 14px;
}
#admin-announcement-container table th {
  font-weight: 600;
  font-size: 14px;
  padding: 12px 11px;
}

#admin-announcement-container table td {
  font-size: 13.5px;
  padding: 20px 10px;
}

#admin-announcement-container .checkmark {
  left: 5px;
}

#admin-announcement-container .outlined {
  border-right: 1px solid #cccccc;
  width: 50px;
}

#admin-announcement-container table {
  border: 1px solid #cccccc;
  margin-top: 30px;
}

#admin-system-maintenance-container .table-borderless td, .table-borderless th{
  border: none !important;
}

#admin-system-maintenance-container .table-borderless {
  margin-top: 30px;
}

.button-left, .button-right {
  display: inline-block;
  padding-right: 15px;
}

.btn-announcement:hover {
  color: #08c484!important;
  background-color: #fff!important;
  border-color: #08c484!important;
}

.btn-announcement-delete:hover {
  color: #000!important;
  background-color: #fff!important;
  border-color: gray;
}

#admin-announcement-container .btn-announcement {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 230px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}
#admin-announcement-container .btn-announcement-delete{
  color: #fff;
  background-color: gray;
  border-color: gray;
  width: 230px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}

#admin-system-maintenance-container table td label {
  float: right;
}
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 35px;
  background: gray;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background: #fff;
}

.react-switch-checkbox:checked + .react-switch-label {
  background: #08C484;
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

#admin-system-maintenance-section {
  /* border-top: 1px solid #cccccc;
  bottom: 20%;
  margin: -10px 4.3%; */
  padding: 35px 30px;
}

.view-user-container table td {
  font-size: 13.5px;
  vertical-align: bottom!important;
}

.view-user-container table th {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #1788C7;
  color: #fff;
  z-index: 1;
  border-top: 0px!important;
}


/* The container */
.container-check {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -15px;
  left: 10px;
  height: 22px;
  width: 22px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #ddd;
}

/* On mouse-over, add a grey background color */
.container-check:hover input + .checkmark {
  background-color: #ddd;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked + .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}


.checkbox-custom {
  display: none;
}

.checkbox-custom-label {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: #fff;
    border-radius: 5px;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    padding: 8px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:after {
    content: "\2714";
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    width: 20px;
    height: 20px;
    background: #2196F3;
    border-radius: 5px;
    top: 0px;
    left: 1px;
}

#admin-add-user-section .view-user-container.col {
  left: 10px;
  position: relative;
}

#admin-add-user-section .add-form-container {
  width: 100%;
}

#admin-add-user-section button {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  float: right;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
}

#admin-add-user-section button:hover {
  color: #08c484!important;
  background-color: #fff!important;
  border-color: #08c484!important;
}

#admin-add-user-section .add-user-container {
  /* border-right: 1px solid #cccccc;
  padding-bottom: 40px; */
}

#admin-add-user-section {
  /* border-top: 1px solid #cccccc;
  bottom: 20%;
  margin: -10px 4.3%; */
  padding: 35px 30px;
}

#container-top {
  padding: 10px 60px;
}

#admin-control .nav-links {
  padding: 1%;
  background-color: #1788C7;
}

#admin-control .nav-logo {
  float: left;
  padding: 10px 50px;
}

#admin-control .nav-buttons {
  float: right;
  padding: 30px 50px;
  height: 60px;
}

#admin-control-login {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 65vh;
}

#admin-control-login form {
  width: 40%;
  margin: auto;
}

#admin-control-login .admin-check-remember {
  position: relative;
  margin-top: auto;
  margin-left: 0;
  margin-right: 2px;
}

#admin-control-login .form-check-label {
  color: #000!important;
}

#admin-control-login span {
  color: #1688C7;
  font-weight: 600!important;
}

#admin-control-login p {
  display: inline-block;
  float: right;
}

#admin-control-login button {
  background-color: #1788c7;
  border-color: #1788c7;
  height: 42px;
  width: 100%;
}

#admin-control-login button:hover {
  color: #1788c7!important;
  background-color: #fff!important;
  border-color: #1788c7;
}

@media (max-width: 768px){
  #admin-control-login form {
    width: 50%;
  }
}
@media (max-width: 425px){
  #admin-control-login form {
    width: 100%;
  }
}
@media (max-width: 320px){
  #admin-control-login p, #admin-control-login .form-check-label {   
    font-size: 12px;
    margin-top: 2px;
  }
}

/* by ian bobo */
#edit-admin-users-info{
  background-image: url(/images/writing.png);
  width: 21px!important;
  border-color: #fff!important;
  background-color: #fff!important;
  background-repeat: no-repeat;
  padding: 0!important;
  height: auto;
  border-radius: 0!important;
  background-position: center;
}
.dropdown{
  float: left;
  margin-right: 10px;
}
#edit-admin-users-info::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0px!important;
  border-right: 0px!important;
  border-bottom: 0!important;
  border-left: 0px!important;
}

.table-users-container {
  height: 515px;
  overflow-y: scroll;
}

table#payment-history-complete th, table#payment-history-complete td {
  padding: 0.20rem;
}
#footer-bottom-less-content {
  bottom: 0;
  width: 100%;
  padding: 10px 0px;
}

@font-face {
    font-family: 'arial' ;
    src: url('/arial.ttf') format('truetype');
}
.arial{
font-family: 'arial', arial;  
}
.maintenance-wrap .maintenance-logo{
    width: 30%!important;;
}
.maintenance-wrap h1{
    font-size: 30px!important;
}
.maintenance-wrap h3{
    font-size: 15px!important;;
}
.maintenance-wrap .content-img{
    width: 25%!important;
}
@media(max-width:991px){
    .maintenance-wrap .maintenance-logo{
        width: 50%!important;
    }
    .maintenance-wrap h1{
        font-size: 30px!important;
    }
    .maintenance-wrap h3{
        font-size: 15px!important;
    }
    .maintenance-wrap .content-img{
        width: 25%!important;
    }
}
@media(max-width:768px){
    .maintenance-wrap .maintenance-logo{
        width: 50%!important;
    }
    .maintenance-wrap h1{
        font-size: 30px!important;
    }
    .maintenance-wrap h3{
        font-size: 15px!important;
    }
    .maintenance-wrap .content-img{
        width: 25%!important;
    }
}
html,body{
    overflow-x:hidden;
}
html *
{
    font-family: Montserrat;
}
p{
    font-size: 14px;
}
li{
    font-size: 14px;
}
label{
    font-size: 14px;
}
select{
    height: 30px;
}
.regular-font-size{
    font-size: 14px!important;
}
.big-font-size{
    font-size: 16px!important;
}
.primary-back-color{
    background-color: white;
}
.secondary-back-color{
    background-color: #1788c7!important;
}

.primary-font-color{
    color: #000000!important;
}
.secondary-font-color{
    color: #1788c7!important;
}
.light-font-color{
    color: white;
}
.dark-font-color{
    color: #333333!important;
}
.font-extra-bold{
    font-weight: 800;
}
.nav-buttons a:hover, .account-info a:hover {
    color: #007bff!important;
}
.font-bold{
    font-weight: 600!important;
}
.sign-up-result label {
    color: #df0018!important;
}
.no-line-height{
    line-height: 0;
}
.custom-line-height{
    line-height: 2.5;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
    border-color: #dc3545!important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none!important;
}
.was-validated .form-control:valid, .form-control.is-valid {
    background-image: none!important;
}
.invalid{
    border-color: #dc3545!important;
}
.valid{
    border-color: #cccccc!important;
}
.invalid_msg{
    display: block!important;
}
.invalid_msg_small{
    font-size: 70%!important;
    display: block!important;
}
.invalid-feedback{
    display: none;
}
input[type=password] {
    font-weight: 900;
}
.alert {
    padding: 20px 35px!important;
    text-align: center;
    margin-bottom: 0!important;
    border: 0!important;
    border-radius: 0!important;
}
.alert-success {
    background-color: #4caf50!important;
}
.alert-danger {
    background-color: #ef5350!important;
}
.alert p{
    margin: 0;
    font-size: 14px;
    color: #ffffff;
    font-weight: 800;
}
#create_account .signup-form{
    max-width: 900px;
    margin: auto;
    padding: 35px;
    border: 2px solid #cccccc;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 70px;
}
#create_account .signup-form input {
    border: 2px solid #cccccc;
}
#create_account .signup-form label {
    font-size: 14px;
    font-weight: 500;
}
#create_account .signup-form select {
    border: 2px solid #cccccc;
}

/* #create_account .signup-form label {
    float: left;
    margin-right: 10px;
}
#create_account .signup-form input {
    float: right;
}
#create_account .signup-form #feedback {
    margin-top: 1px;
    font-size: 80%;
    color: #dc3545;
    margin-left: 10px;
    width: 100%!important;
    font-weight: 500;
} */
#create_account .signup-form button {
    background-color: #007bff!important;
    border-color: #007bff!important;
    width: 185px!important;
    padding: 10px;

}
#create_account .signup-form button:hover {
    color: #007bff!important;
    background-color: white!important;
    border-color: #007bff!important;
    width: 185px!important;
    padding: 10px;

}
.btn-forgot-password{
    cursor: pointer;
}
.btn-forgot-password:hover{
    text-decoration: underline;
}    
#reset_password #main-form{
    margin: 100px 0px 200px;    
}
#reset_password #main-form .form-row{
    width: 352px;
    margin: auto;    
}
#reset_password #main-form .form-row input{
    width: 100%;
    height: 42px;
    border-radius: 5px;
    border: 2px solid #cccccc;   
}
#reset_password #main-form .form-row label{
    margin-top: 0.5rem;
    margin-bottom: 3px;
}
#reset_password #main-form .form-row button{
    margin-left: auto;
    background-color: #df0018!important;
    border-color: #ef828e!important;
    border-radius: 10px;
    width: 166px!important;
    height: 42px;
}

#reset_password #main-form .form-row button:hover{
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important;
}

#account_number .custom-margin{
    margin: 85px 0px 40px;
}

#create_account .custom-margin{
    margin: 40px 0px 100px;
}
#create_account #cancel{
    padding: 10px 0;
    text-align: right;
}
#create_acount .gpa-form label{
    line-height: 0;
}
#header-main .nav-ian{
    height: 80px;
    margin-bottom: 70px;
}
#header-main .nav-ian .nav-brand img{
    height: auto;
    width: 100%;
}
#footer-main .fa-ul{
    padding-left: 15px;
    margin: 5px;
}

#footer-top{
    padding: 70px 25px;
}
#footer-bottom .footer-bottom-text{
    text-align: center;
    padding: 28px 13px 17px;
}

#dashboard #footer-main {
    padding: 20px 0px;
}

#footer-bottom .footer-bottom-icons{
    float: right;
    padding: 15px 13px;
}
#footer-bottom{
    -webkit-align-items: center;
            align-items: center;
}
#footer-bottom .footer-bottom-content
{
    width: 100%;
}
.gpa-form .btn-primary{
    background-color: #007bff!important;
    border-color: #007bff!important;
    width: 150px!important;
}
.gpa-form .btn-primary:hover {
    color: #007bff;
    border-color: #007bff!important;
    background-color: white!important;
}
#login .center-element{
    top: 50%;
    position: relative;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
input#terms-condi {
    margin-right: 5px;
    position: relative;
    top: 0px;
    display: inline-block;
    width: auto;
}
.custom-label-terms {
    position: relative;
    top: -13px;
}
.custom-error-terms {
    margin-top: -17px!important;
}
#sign-up-form input[type="checkbox"]:focus{
    border-color: #fff!important;
    box-shadow: none!important;
}
#login .login-banner{
    /* background-image: url(/images/login-banner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}
#login .login-banner .custom-column-height{
    height: 100vh;
}
#login .login-banner .padding-right{
    top: 50%;
    position: relative;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 0px 100px;
}
#login .login-banner .padding-right.light-font-color h1 {
    font-size: 55px;
}
#login .login-form{
    padding: 35px 110px 5px;
    text-align: center;
}
#login .login-form button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 100%;
}

#login .login-form button:hover{
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important;
}

#login .login-form label{
    float: left;
}

#login .login-form input{
    padding: 10px;
    height: 42px;
    width: 100%;
}
#login .remember-me{
    padding: 0px 20px 0px;
}
#login .forgot{
    text-align: right;
}
#login #login_actions button {
    background-color: #1788c7;
    border-color: #1788c7;
    height: 42px;
    width: 100%;
}
#login #login_actions button:hover {
    color: #1788c7;
    background-color: white!important;
    border-color: #1788c7!important;
}
#login .remember-me {
    padding: 0px 0px 0px 35px;
}
.forgot-modal .modal-content{
    padding: 15px;
    border-radius: 20px;
}
.forgot-modal .modal-content .modal-header{
    border-bottom: 0px; 
}
.forgot-modal .modal-content .modal-footer{
    border-top: 0px; 
}
.forgot-modal .modal-content .modal-header button{
    font-size: 35px;
    font-weight: 400;
    padding: 10px 15px;
} 
.forgot-modal .modal-content .modal-body input{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 10px 13px;
}    
.forgot-modal .modal-content .modal-body {
    padding: 0px 30px;
}  
.hint-modal .modal-content .modal-body {
    padding: 0px;
} 
.forgot-modal .modal-content .modal-body p {
    font-size: 18px;
}  
.forgot-modal .modal-content .modal-title h5 {
    font-size: 18px!important;
}  
.forgot-modal .modal-content .modal-footer button {
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 24px;
    padding: 10px;
    width: 140px;
    margin-left: 15px;
} 
.forgot-modal .modal-body label {
    color:#EF5350!important;
}
.forgot-modal .modal-content .modal-footer button:hover {
    color: #007bff;
    background-color: white!important;
    border-color: #007bff!important;
} 
.fade .modal .show{
    padding-right: 0px!important;
}
div#login_help p {
    margin-bottom: 5px;
}
@media (min-width: 530px){
    #create_account .signup-form select {
        border: 2px solid #cccccc;
    }
    
    #create_account .signup-form label {
        float: left;
        margin-right: 10px;
    }
    #create_account .signup-form input {
        float: right;
    }
    #create_account .signup-form #feedback {
        margin-top: 1px;
        font-size: 80%;
        color: #dc3545;
        margin-left: 10px;
        width: 100%!important;
        font-weight: 500;
    }
}
@media (min-width: 576px){
    .forgot-modal{
        max-width: 585px!important;
    }
    .message-modal{
        max-width: 585px!important;
    }
}

@media (min-width: 1331px){
    
    #login .login-form .custom-width{
        width:452px!important;
        margin: auto;
    }
    #login #login_actions .custom-width{
        width:452px!important;
        margin: auto;
        padding: 0;
    }
    
}
#login .login-form .custom-width{
    width:auto;
}
#login #login_actions{
    padding: 5px 110px 5px;
}

#pay_now #paynow-form{
    max-width: 650px;
    margin: auto;
    padding: 35px 35px 10px;
    border: 2px solid #cccccc;
    border-radius: 10px;
    margin-top: 35px;
}
#pay_now #paynow-form .react-datepicker-wrapper {
    display: block!important;
}
#pay_now #paynow-form input{
    border: 2px solid #cccccc;
}
#pay_now #paynow-form .gpa-form-button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 165px!important;
    padding: 10px;
    float: right;
}

#pay_now #paynow-form .gpa-form-button:hover{
    color: #08c484;
    border-color: #08c484!important;
    background-color: white!important;
}

.modal-body .hint img{
    height: auto;
    width: 100%;
}
#pay_now .progress-line hr{
    border-top: 3px solid rgba(0, 0, 0, 0.1);
    max-width: 320px;
}

#pay_now .progress-balls{
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: -10px;
}
#pay_now .progress-balls .step1 {
    display: inline-block;
    margin: 0px 20px!important;
}

#pay_now .progress-balls .step2, #pay_now .progress-balls .step3 {
    display: inline-block;
    margin: 0px 45px;
}
#pay_now .progress-balls .step1 p,#pay_now .progress-balls .step2 p,#pay_now .progress-balls .step3 p {
    margin-top: 10px;
}
#pay_now #gpa-form-actions #cancel{
    padding: 10px 0;
    text-align: left;
}
#pay_now #title{
    margin: 100px 0px 40px;
}
#pay_now #progress{
    margin-bottom: 60px;
}
#pay_now #bottom-text{
    text-align: center;
    margin-right: 220px;
    margin-bottom: 30px;
}
#terms_and_conditions #title, #privacy_policy #title{
    margin: 100px auto 0px;
}
#terms_and_conditions #body, #privacy_policy #body{
    max-width: 850px;
    margin: 45px auto;
}
#terms_and_conditions #body p, #privacy_policy #body p{
    font-weight: 500;
}

#terms_and_conditions #body button, #result_message #body button, #privacy_policy #body button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 100%;
}
#terms_and_conditions #body button:hover, #result_message #body button:hover, #privacy_policy #body button:hover{
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important;
}
#result_message {
    margin: 120px 0px 60px;
}
.spinner-border-sm {
    margin-bottom: 3px;
}
.link-hover:hover{
    cursor: pointer;
    text-decoration: underline;
}
div#login_help {
    padding: 10px 35px 35px;
}
#login .login-footer {
    padding: 0px 12px 15px;
    margin-top: 50px;
}
#login .login-footer  .bottom-img {
    display: none!important;
}
/* //sign-up */
.premise-address-modal .modal-content .modal-header button {
    font-size: 35px;
    font-weight: 400;
    padding: 10px 15px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered {
    max-width: 750px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content {
    padding: 20px 15px;
    border-radius: 10px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content .modal-header{
    border-bottom: 0px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content {
    padding: 20px 15px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content .modal-body .address-details-wrap label {
    font-weight: 600;
    font-size: 16px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content .modal-body .modal-msg-wrap label {
    font-style: italic;
}
#sign-up-stage{
    margin-top: 25px;
}
#sign-up-stage .border-style{
    border: 1px solid #e5e5e5;
    max-width: 800px;
    margin: auto;
}
#sign-up-stage .border-left-right{
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
#sign-up-stage .content {
    padding: 10px 10px 0px;
}
#sign-up-stage .content span{
    margin-right: 10px;
}
#sign-up-stage .selected {
    background-color: #1788c7;
    color: #fff;
}
#sign-up-stage .done{
    color: #47c485;
    font-weight: 600;
}
#sign-up-title{
    margin-top: 95px;
}
#sign-up-form {
    margin-top: 50px;
}

#sign-up-form .form-wrap{
    border: 2px solid #cccccc;
    padding: 35px 45px 35px;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
}
#sign-up-form .form-wrap .form-input{
    border: 2px solid rgb(204, 204, 204);
}
#sign-up-form .form-wrap .form-input-btn-wrap .form-input{
    width: 335px;
    margin-right: 15px;
    border-radius: 5px;
    border: 2px solid rgb(204, 204, 204);
    padding: 5px 10px;
}
#sign-up-form .form-wrap .form-input-btn-wrap .form-button{
    padding: 7px;
    width: 155px;
    background-color: #47c485;
    border-color: #47c485;
}
#sign-up-form .form-wrap .form-input-btn-wrap .form-button:hover{
    background-color: white;
    border-color: #47c485;
    color: #47c485;
}
#sign-up-form .form-wrap .form-link-wrap{
    padding-top: 5px;
}
#sign-up-form-bottom .content-wrap{
    max-width: 600px;
    margin: auto;
}
#sign-up-form-bottom .content-wrap .left label{
    color: #df0018!important;
    font-size: 13px;
    font-weight: 500;
    float: left;
}
#sign-up-form-bottom .content-wrap .right label{
    float: right;
}
#sign-up-form-bottom .content-wrap .right label span:hover{
    text-decoration: underline;
    cursor: pointer
}
#sign-up-form-bottom{
    margin: 25px 0px 40px;
}
#signup-form-buttons{
    margin-top: 15px;
    margin-bottom: 60px;
}
#signup-form-buttons .buttons-wrap{
    width: 600px;
    margin: auto;
    padding: 30px 35px 0px;
}
#signup-form-buttons .left {
    float: left;
}
#signup-form-buttons .right {
    float: right;
}
#signup-form-buttons .right button{
    padding: 7px;
    width: 155px;
    background-color: #47c485;
    border-color: #47c485;
    font-size: 14px;
}

#signup-form-buttons .left .btn-back {
    border: 2px solid rgb(204, 204, 204);
    background-color: white;
    color: #b2b2b2;
    font-size: 13px;
    padding: 8px 30px;
    margin: 0px 5px 0px 0px;
}

#signup-form-buttons .left .btn-cancel {
    border: 0;
    background-color: white;
    color: #b2b2b2;
    font-size: 13px;
    padding: 8px 15px;
}
#signup-form-buttons .right button:hover{
    background-color: white;
    border-color: #47c485;
    color: #47c485;
}
section#success-message-icon {
    margin-top: 110px;
}
section#success-message-title {
    margin-top: 30px;
}
section#success-message-body {
    max-width: 850px;
    margin: 30px auto 0px;
}
section#success-message-button button {
    padding: 7px;
    width: 155px;
    background-color: #47c485;
    border-color: #47c485;
}
section#success-message-button {
    margin-top: 30px;
    margin-bottom: 90px;
}
@media (max-width: 431px){
    #pay_now .progress-balls .step1, #pay_now .progress-balls .step2, #pay_now .progress-balls .step3 {
        display: inline-block;
        margin: 0px 15px!important;
    }
}
@media (min-width: 992px){
    #terms_and_conditions #body button, #result_message #body button, #privacy_policy #body button {
        background-color: #08c484;
        border-color: #08c484;
        height: 42px;
        width: 25%!important;
    }
}
@media (min-width: 523px) and (max-width: 991px){
    
    #login .login-form .custom-width{
        width:452px;
        margin: auto;
    }
    #login #login_actions .custom-width{
        width:452px;
        margin: auto;
    }
    
}
@media (min-width: 1285px) and (max-width: 1330px){
    
    #login #login_actions .custom-width {
        padding: 0px 110px;
    }
    
}
@media (min-width: 1200px) and (max-width: 1284px){
    
    #login .remember-me {
        padding: 0px 0px 0px;
        margin: 0 auto;
    }    
    #login #login_actions .custom-width{
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 1199px){
    #login #login_actions .custom-width{
        text-align: center;
    }
    #login #login_actions .custom-width .remember-me{
        padding: 0px;
    }
    #login #login_actions .custom-width .forgot{
        text-align: center;
    }
}
@media (min-width: 992px){
    .gpa-form{
        border: 2px solid #cccccc;
        padding: 35px 58px 45px;
        border-radius: 10px;
    }
    .gpa-form-input {
        width: 350px;
        margin-right: 15px;
        border-radius: 5px;
        border: 2px solid #cccccc;
    }
    #create_account .gpa-form-input {
        width: 100%;
        margin-right: 15px;
        border-radius: 5px;
        border: 2px solid #cccccc;
    }
    #create_account .signup-form #gpa-form-actions {
        margin-top: 45px;
    }
}
@media (max-width: 642px){
    #login .login-form{
        padding: 35px 35px 5px 35px;
        text-align: center;
    }
    #login #login_actions{
        padding: 35px 35px 5px 35px;
        text-align: center;
    }
}
@media (max-width: 991px){
    #login #login_actions{
        padding: 5px 35px 35px 35px;
        text-align: center;
    }
    #footer-main #contacts{
        line-height: 1.3;
        
    }
    #create_account #gpa-form-actions{
        text-align: center;
    }
    #create_account #info-content{
        float: none!important;
    }
    
    #create_account #sign-up,#create_account #cancel{
        text-align: center;
    }
    #login .login-banner{
        display: none;
    }
    #login .login-footer {
        text-align: center;
    }
    /* for sign up */
    #sign-up-form .form-wrap {
        padding: 25px!important;
    }
    #sign-up-form .form-wrap .form-input-btn-wrap{
        text-align: center!important;
    }
    #sign-up-form .form-wrap .form-input-btn-wrap .form-input {
        width: 100%!important;
        margin-right: 0px!important;
    }
    #sign-up-form .form-wrap .form-input-btn-wrap .form-button {
        margin: 10px 0px!important;
    }
    #sign-up-form .form-wrap .form-link-wrap {
        text-align: center;
    }
    #sign-up-form-bottom {
        text-align: center;
    }
    #sign-up-form-bottom .content-wrap .left label {
        float: none;
    }
    #sign-up-form-bottom .content-wrap .right label {
        float: none;
    }
    #signup-form-buttons .buttons-wrap {
        width: 100%;
        margin: auto;
        padding: 30px 35px 0px;
    }
}
@media (max-width: 1199px){
    
    #create_account #sign-up{
        padding: 0;
    }
    #create_account #sign-up button{
        margin: 0;
    }
    #create_account #cancel {
        padding: 5px 15px;
    }
    .gpa-form{
        width: 100%;
        border: 2px solid #cccccc;
        padding: 35px 35px 45px;
        border-radius: 10px;
    }
    .gpa-form .mobile-view{
        text-align: center;
    }    
    .gpa-form .btn-primary{
        margin-top: 15px;
    } 
    .gpa-form-input {
        width: 100%;
        margin-right: 15px;
        border-radius: 5px;
        border: 2px solid #cccccc;
    }
}
@media (max-width: 991px){
    section#pay_now .progress-line {
        display: none;
    }
    #pay_now #paynow-form {
        margin-top: 120px;
    }
    /* #pay_now .progress-balls .step1, #pay_now .progress-balls .step2, #pay_now .progress-balls .step3{
        display: none
    } */
    #account_number #quick_links1 .mobile-view,#account_number #quick_links2 .mobile-view{
        margin: auto;
    }
    #title div{
        margin: auto;
    }
    #footer-bottom .footer-bottom-text{
        float: none;
        margin: auto;
        padding: 10px;
        text-align: center;
    }
    #footer-bottom .footer-bottom-text p{
        margin: 0px;
    }
    #footer-bottom .footer-bottom-icons{
        float: none;
        margin: auto;
        margin-bottom: 15px;
        text-align: center;
        padding: 0;
    }
    #pay_now #paynow-form .gpa-form-button{
        float: none;
    }
    #pay_now #paynow-form #proceed{
        text-align: center!important;
    }
    #pay_now #gpa-form-actions #cancel{
        text-align: center!important;
        float: none;
    }
    #login .login-footer {
        margin-top: 0px!important;
    }
}   
@media (max-width: 767px){
    #pay_now #bottom-text{
        margin-right: 85px;
    }
}
@media (max-width: 480px){
   
    .progress-line{
        display:none!important
    }
    .show{
        display:block!important
    }
    .hide{
        display:none!important
    }
    #pay_now #bottom-text{
        text-align: center;
        margin: 0;
    }
    /* for sign up */
    #signup-form-buttons {
        margin: 15px auto 0px!important;
        text-align: center!important;
    }
    #signup-form-buttons .left, #signup-form-buttons .right {
        float: none;
    }
    #signup-form-buttons .left{
        margin: 10px 0px;
    }
    #signup-form-buttons .buttons-wrap {
        padding: 0px 35px;
    }
}
@media (max-width: 450px){
    #reset_password #main-form .form-row{
        width: auto;
        margin: auto 20px;   
    }
    #reset_password #main-form .form-row button{
        margin: auto!important;
    }
    div#login_help {
        padding: 10px 30px 35px;
    }
    div#login_help p {
        margin-bottom: 0;
    }
}
@media (max-width: 420px){
    div#login_help p {
        font-size: 3.5vw;
    }
}
@media (max-width: 370px){
    #login .login-footer p{
        font-size: 3.5vw;
    }
}


/* ALL MEADIA QUERIES HERE! */

/* new shits */

.font-bold-lite {
    font-weight: 500!important;
}

#faq .card-header {
    padding: 15px;
    margin-bottom: 0;
    background-color: white;
    border-bottom: 0px;
}
#faq .card-body {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0px 30px 15px;
    margin-top: -20px;
}
#faq .accordion > .card:first-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125)!important;
    border-bottom-right-radius: 0.25rem!important;
    border-bottom-left-radius: 0.25rem!important;
}
#faq .card {
    margin: 10px 0px;
}
#faq .accordion > .card:last-of-type {
    border-top-left-radius: 0.25rem!important;
    border-top-right-radius: 0.25rem!important;
}
#faq button.btn.btn-link {
    font-weight: 500;
    color: black;
}
#faq .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125)!important;
    border-radius: 0.25rem!important;
}
section#faq h5 {
    margin: 15px 0px;
}
section#faq {
    margin-bottom: 40px;
}

#faq button.btn.btn-link:focus, #faq button.btn.btn-link:hover {
    text-decoration: none;
}
#faq #go-back {
    background-color: #08c484;
    border-color: #08c484;
    width: 200px;
    height: 50px;
    margin-top: 25px;
}
@media(min-width: 1367px){
    .height-lg-vh{
        height: 100vh;
    }
}

/* BANNER TOP ANNOUNCEMENT */
@media(max-width: 991px){
    #login .announcement-banner-top{
        padding: 10px 20px!important;
    }
}
#login .announcement-banner-top {
    background-color: #08c484;
    text-align: center;
    padding: 10px;
    font-weight: 600;
}

#login .announcement-banner-top label {
    margin-bottom: 0;
    font-size: 14px;
    color: #fff;
}

#login .announcement-banner-top a {
    color: #fff;
}

#login .announcement-banner-top a:hover {
    font-weight: 500;
}
  

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

#paperless-billing-modal div#cancel, #paperless-billing-modal div#save {
    display: inline-block;
}

#paperless-billing-modal div#cancel {
    vertical-align: middle;
    width: 120px;
    height: 42px;
    padding: 5px;
}

#paperless-billing-modal label.on:before {
    content: 'ON';
    margin-left: 15px;
    color: white;
}

#paperless-billing-modal  label.off:after {
    content: 'OFF';
    margin-left: 37px;
    color: white;
}


.customer-feedback .below-container {
    margin-top: 100px;
    margin-left: -24px;
    line-height: 2;
}

#pay-now-modal .react-datepicker__month .react-datepicker__month-text, #pay-now-modal  .react-datepicker__month .react-datepicker__quarter-text {
    width: 3rem!important; 
}
#pay-now-modal .react-datepicker__input-container input {
    width: 100%;
    padding: 0px 10px;
}
.red-font {
    color: #df0018!important;
}
a:hover {
    text-decoration: none!important;
}
#login .bottom-img {
    margin-top: 20px;
}
#dashboard .nav-links{
    padding-right: 80px;
}
#dashboard .nav-links a{
    display: inline-block;
    color: white;
    padding: 5px 20px;
}
#dashboard .nav-links a p {
    margin: 0px;
}
#dashboard .nav-main{
    padding: 13px 80px 0px;
}
#dashboard .nav-main .float-left .gpa_logo {
    height: auto;
    width: 245px;
}
#dashboard .nav-main .nav-buttons a {
    padding-top: 82px;
    padding: 0px 30px 0px 15px;
    font-size: 14px;
}
#dashboard .nav-main .nav-buttons a span{
    padding: 10px;
}

#dashboard h5{
    font-weight: 600;
}
#dashboard .main hr{
    border-top: 2px solid #cccccc;
}
#dashboard .main .survey_wrap{
    padding-bottom: 30px;
}
#dashboard .main .survey_wrap .featured_image{
    display: inline-block;
}
#dashboard .main .survey_wrap .content{
    display: inline-block;
    vertical-align: middle;
    max-width: 640px;
}
#dashboard .main .survey_wrap .content a{
    font-weight: 600;
    font-size: 12px;
}
#dashboard .customer-feedback button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 185px!important;
    padding: 10px;
    float: right;
}
#dashboard .customer-feedback button:hover {
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important;
}
#dashboard .main .edit-form{    
    max-width: 750px;
    margin: auto;
}

#dashboard .main .edit-form input{    
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form select{    
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form label{    
    font-weight: 500;
    color: #333333;
}

#dashboard .main .edit-form h5 {
    font-size: 18px;
    color: #333333;
}

#dashboard .main .edit-form .change-pass{
    padding: 0px 0px 50px 0px;
}
#dashboard .main .edit-form .change-pass a{
    font-weight: 500;
}
#dashboard .main .edit-form .change-pass .float-left h5{
    margin-top: 15px; 
}
#dashboard .main .edit-form .change-pass .float-right{
    margin-top: 5px;
    font-size: 15px;
}
#dashboard .main .edit-form .change-pass button {
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 185px!important;
    padding: 10px;
}
#dashboard .main .edit-form .paperless-billing{
    padding: 10px 25px 30px 0px;
    font-size: 15px;
}
#dashboard .main .edit-form .paperless-billing h5{
    margin: 0px;
}
#dashboard .main #title {
    max-width: 875px;
    margin: auto;
}
#dashboard .main #form-actions{
    padding: 20px 40px;
}
#dashboard .main #form-actions #save button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 185px!important;
    padding: 10px;
}

#dashboard .main #form-actions #save button:hover{
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important
}
#dashboard .main #form-actions #cancel{
    padding: 10px 0;
    text-align: right;
}
.nav-buttons{
    float: right;
}
.nav-buttons .logout{
    margin: 0px;
    color: #ababab;
    cursor: pointer;
}
#dashboard .main .account-overview{
    margin-left: 0px;
    margin: auto;
}
#dashboard .main .account-overview .bill-summary .header{
    border: 1px solid #278ec8;
    background-color: #278ec8;
    color: white;
    padding: 10px 20px;
}
#dashboard .main .account-overview p{
    font-weight: 500;
    
}

#dashboard .main .account-overview .bill-summary .header p{
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;
}
#dashboard .main .account-overview .bill-summary .header select{
    display: inline-block;
    width: 480px;
}

#dashboard .main .account-overview .bill-summary .border{
    border: 1px solid;
    border-top: 0px;
    padding: 10px;
}

#dashboard .main .account-overview .bill-summary .border-top-0{
    padding: 24px;
}

#dashboard .main .account-overview .bill-summary .border p{
    margin: auto;
}
#dashboard .main .account-overview .bill-summary .pay-now{
    padding: 40px;
}
#dashboard .main .account-overview .bill-summary .pay-now button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 100%;
}    
#dashboard .main .account-overview .bill-summary .pay-now button:hover{
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important;
} 
#dashboard .main #info-wrap{
    padding: 30px 30px;
    width: 100%;
}

#dashboard .main #info-wrap .account-info .header label{
    display: inline-block;
    margin-right: 60px;
}

#dashboard .main #info-wrap .account-info .header a{
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    float: right;
}

#dashboard .main #info-wrap .account-info .body p{
    margin: 0px;
}
#dashboard .main #info-wrap .contact-info .body p{
    margin: 0px;
}

#dashboard .main .my-bills-info p{
    margin: 0px;
}

#dashboard .main #my-bills-table .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px;
    text-align: center;
    background-color: #1788c7;
    color: white;
}
#dashboard .main #my-bills-table .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
    text-align: center;
}
/* #welcome-wrap{
    max-width: 1035px;
} */

#bar-chart-wrap {
    /* padding: 90px 250px 30px 80px; */
    margin: 15px 0px;
}

#bar-chart-wrap .top label, #bar-chart-wrap .top p, #bar-chart-wrap .top select{
    display:inline-block;
}

#bar-chart-wrap .top p{
    font-size: 14px;
    font-weight: 600;
    padding-right: 10px;
}
#bar-chart-wrap .top select{
    font-size: 14px;
}
#bar-chart-wrap .top{
    padding: 30px 0px 15px;
}
#bar-chart-wrap .bottom{
    padding: 0px;
}

#bar-chart-wrap .bottom .graph-label{
    margin: auto;
    text-align: center;
    padding-left: 45px;
}

#bar-chart-wrap .bottom .graph-label label{
    /* transform: rotate(-90deg); */
}

#bar-chart-wrap .bottom .col-lg-3{
    margin: auto;
}
#dashboard #nav-mobile{
    padding: 25px 25px;
}    
#dashboard #nav-mobile #basic-navbar-nav {
    padding: 25px 10px 10px;
}
#dashboard #nav-mobile #basic-navbar-nav .mr-auto.navbar-nav a {
    margin: 5px 0px;
}
#dashboard #nav-mobile .navbar-brand .gpa_logo{
    height: auto;
    width: 215px;
}
div#responsive-navbar-nav {
    padding: 25px;
}
.mr-auto.navbar-nav a{
    color: white;
}
#dashboard .main #info-wrap .account-info hr, #dashboard .main #info-wrap .contact-info hr  {
    margin-top: 0px;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#loading{
    position: relative;
    margin: auto;
    text-align: center;
    height: 100vh;
}
#loading .spinner-border {
    top: 25%;
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: text-bottom;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
section#dashboard .nav-main .nav-logo{
    float: left;
}
section#dashboard .nav-main .nav-buttons {
    float: right;
    height: 60px;
    padding: 20px 0px;
}
#dashboard .main .account-overview .bill-summary .header input{
    padding: 1px 5px;
    pointer-events: none;
    border-radius: 5px;
    width: 500px;
}
.navbar-toggler {
    float: right;
}
#dashboard .main #form-actions #save, #dashboard .main #form-actions #cancel{
    text-align: center;
}
.nav-buttons .logout:hover {
    text-decoration: none;
}

@media (max-width: 575px){
    #dashboard .main {
        padding: 10px 25px;
    }
}
@media (max-width: 360px){
    #dashboard #footer-main .float-left p{
        font-size: 3.5vw;
    }
    #dashboard .main .survey_wrap {
        padding-bottom: 30px;
        text-align: center;
    }
    #dashboard #nav-mobile .navbar-brand .gpa_logo {
        height: auto;
        width: 185px;
    }
    #dashboard .main #welcome-wrap h3{
        font-size: 7.5vw;
    }
}
@media (max-width: 420px){
    #dashboard .main #info-wrap{
        bottom: -45px!important; 
    }
    
    #dashboard .main #info-wrap .account-info .header a{
        margin: 10px 0px;
        float: none;
    }
}
@media (min-width: 421px) and (max-width: 501px){
    #dashboard .main #info-wrap{
        bottom: -10px!important;
    }
    
}
@media (max-width: 570px){
    #dashboard .main .account-overview .bill-summary .header{
        text-align: center;
    }
    #dashboard .main .account-overview .bill-summary .header input{
        width: 100%!important;
    }
}

@media (max-width: 674px){
    #dashboard #footer-main .float-left{
        float: none!important;
        padding-left: 0!important;
    }
    #dashboard #footer-main .float-right{
        float: none!important;
        padding-right: 0px!important;
    }
}
@media (min-width: 992px){
    #nav-desktop{
        display: block;
    }
    #nav-mobile{
        display: none;
    }
    #dashboard .main #form-actions #cancel {
        text-align: right;
    }
    #survey-list select, #survey-list textarea{
        margin: 10px 0px
    }
    /* #survey-list .required-fields, #survey-list .not-required-field{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 315px;
    } */
    #welcome-wrap .account-number{
        float: right;
        margin: 15px 0px;
    }
    .text-right-lg{
        text-align: right
    }
}
@media (max-width: 480px){
    #dashboard .main .edit-form .paperless-billing {
        padding: 10px 25px 10px 0px;
        font-size: 15px;
    }
    #dashboard .main .edit-form .paperless-billing .float-left {
        float: none!important;
    }
    #dashboard .main .edit-form .paperless-billing .float-right {
        float: none!important;
        margin-top: 10px;
    }
}
@media (max-width: 991px){
    #nav-desktop{
        display: none;
    }
    #nav-mobile{
        display: block;
    }
    #bar-chart-wrap .bottom{
        text-align: center;
    }
    #bar-chart-wrap .bottom .graph-label{
        padding: 20px 0px 20px 0px!important;
    }
    #bar-chart-wrap .bottom .graph-label label{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    #dashboard .main #info-wrap{
        bottom: 15px;
    }
    #dashboard #footer-main{
        bottom: -50px;
    }
    #dashboard .main .account-overview .bill-summary .header select{
        width: 100%!important;
        text-align: center;
    }
    section#dashboard .nav-main .nav-buttons {
        float: none;
        text-align: center;
    }
    section#dashboard .nav-main .nav-logo {
        float: none;
        text-align: center;
    }
    #dashboard .nav-links {
        padding: 0px;
        text-align: center;
    }
    #loading .spinner-border{
        left: 44%
    }
    #bar-chart-wrap .scroll-hidden-mobile{
        width: 100%;
        padding: 0px;
        margin: 0px 30px 0px 30px;
        overflow-y: hidden;
    }
    #bar-chart-wrap #bar-scroll-mobile{
        overflow-y: scroll;
        width: 650px;
    }
    #bar-chart-wrap .top {
        padding: 30px 0px 0px;
    }
    #survey-list select, #survey-list textarea{
        margin: 10px 0px
    }
}
#survey-list .thank-you-msg h5{
    font-weight: 400!important;
    font-style: italic;
}
#survey-list button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 150px!important;
    padding: 10px; 
    float: right;
}

#survey-list select, #survey-list textarea{
    margin-right: 15px;
    border-radius: 5px;
    border: 2px solid #cccccc;
}
#survey-list .btn-wrap {
    margin: 30px 0px;
}

#survey-list .thank-you-msg {
    margin: 40px 0px;
}

button .spinner-border-sm {
    margin-bottom: 1px;
    margin-right: 5px;
}

#survey-list .btn-wrap button {
    margin: 0px 0px 40px;
}
#dashboard .main .customer-feedback input, #dashboard .main .customer-feedback select, #dashboard .main .customer-feedback textarea {
    border-radius: 5px;
    border: 2px solid #cccccc;
}
div#my-bills-table td p {
    margin: 0;
    cursor: pointer;
}
#dashboard .main {
    background-color: white;
}
#view-bill {
    width: 1140px;
    height: auto;
    border: solid 1px black;
}
#view-bill .bill-template {
    position: relative;
}
#view-bill .bill-template img {
    width: 100%;
}
#view-bill .bill-template .charges-info{
    position: absolute;
    top: 22.2%;
}
p.charges-info.prevBalance {
    left: 6%;
    width: 11.5%;
    text-align: center;
}
p.charges-info.amountPaid {
    left: 20%;
    width: 10%;
    text-align: center;
}
p.charges-info.adjustments {
    left: 30.5%;
    width: 13%;
    text-align: center;
}
p.charges-info.corrections {
    left: 43%;
    width: 13.5%;
    text-align: center;
}
p.charges-info.totalAmountDue {
    left: 69%;
    width: 12%;
    text-align: center;
}
p.charges-info.amountDueBy {
    left: 80.5%;
    width: 14.5%;
    text-align: center;
}
p.charges-info.currCharges {
    left: 57%;
    width: 10%;
    text-align: center;
}
#view-bill .bill-template .consumption-details-left {
    position: absolute;
    top: 27.8%;
    left: 20%;
}
#view-bill .bill-template .consumption-details-right {
    position: absolute;
    top: 28%;
    left: 55.8%;
}
#view-bill .bill-template .top-account-info {
    position: absolute;
    top: 8%;
    left: 71%;
}
#view-bill .bill-template .bill-segment{
    position: absolute;
    top: 42%;
    left: 6%;
    right: 6%;
}
#view-bill .bill-template .bill-message{
    background-color: yellow;
    border: 1px solid;
    height: 50px;
    width: 88.2%;
    position: absolute;
    top: 60%;
    left: 6%;
    right: 6%;
    font-size: 14px;
    text-align: center;
    padding: 12px;
    font-weight: 700;
}
#view-bill .bill-template .bottom-bill-info{
    position: absolute;
    top: 80.6%;
    left: 80%;
}
#view-bill .bill-template .bottom-bill-info p {
    margin: 1% 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .bottom-acc-info{
    position: absolute;
    top: 90%;
    left: 31.5%;
}

#view-bill .bill-template .bottom-acc-info p{
    margin: 0;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 500;
}
#view-bill .bill-template canvas{
    position: absolute;
    width: 12%
}
#view-bill .bill-template .barcode{
    position: absolute;
    top: 80.2%;
    left: 55%;
}
#view-bill .bill-template .barcode img{

}
#view-bill .bill-template .bar-chart{
    width: 21%;
    height: 11%;
    position: absolute;
    top: 26%;
    left: 72.5%;
}
#view-bill .bill-template .bar-chart p {
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    margin: 2% 0;
}

#view-bill .bill-template .barcode-img{
    /* position: absolute; */
    top: 80.2%;
    left: 54%;
    width: 12%;
}
#view-bill .bill-template .billing-period {
    position: absolute;
    top: 17%;
    left: 75%;
}
#view-bill .bill-template .date-applied {
    position: absolute;
    top: 17%;
    left: 34%;
}
#view-bill .bill-template .billing-period p, #view-bill .bill-template .date-applied p {
    margin: 0 0 11% 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .bill-segment .calcLine {
    margin: 0 3%;
}

#view-bill .bill-template .bill-segment p {
    margin: 0;
    display: inline-block;
}
#view-bill .bill-template .bill-segment .bill-segment-content .calcLine span {
    margin-right: 15%;
}
#view-bill .bill-template .bill-segment .bill-segment-content p, #view-bill .bill-template .bill-segment .bill-segment-content span {
    font-family: sans-serif;
    font-size: 14px;
}


#view-bill-actions .download, #view-bill-actions .back{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    margin-bottom: 10px
}
#view-bill-actions .download{
    float: right;
}
#view-bill .bill-template .top-account-info p{
    margin: 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .charges-info{
    margin: 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .consumption-details-left p {
    margin: 0 0 7% 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .consumption-details-right p {
    margin: 0 0 11% 0;
    font-family: sans-serif;
    font-size: 14px;
}
.overlay{
    background-color:#ccc;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}
#account-numbers .table-hover tbody tr:hover {
    background-color: #76B6E4!important;
}
.sign-up-result .text-red {
    color: #df0018!important;
}
table#account-numbers {
    margin: 0;
}
table#account-numbers thead tr th {
    background-color: #e3e5e7;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 12px
}
table#account-numbers td {
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
}
table#account-numbers td div {
    margin:auto
}
div#scrollable-container{
    overflow: auto;
    height: 155px;
}
.account-summary .table-overflow{
    height: 340px;
    border: 1px solid #dee2e6;
}
table#account-numbers .highlighted{
    background-color: #e6f2f9;
    transition: 0.3s;
}
table#account-numbers .checkmark{
    position: absolute;
    left: 5px;
    height: 22px;
    width: 22px;
    top: -20px;
}

.account-summary .account-summary-label{
    background-color: #1788c7;
    padding: 15px 25px;
}
.account-summary .account-summary-label label{
    margin: 0;
}

.sub-total button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 150px;
}

.fixed_header tbody{
    display:block;
    overflow:scroll;
    height:200px;
    width:100%;
}
.fixed_header thead tr{
    display:block;
}


.dollar-sign{
    width:9%;
    vertical-align: middle;
    margin:auto;
    display: inline-block;
}
table#account-numbers td{
    text-align: center;
}
table#account-numbers tbody tr td .invalid-amount{
    text-align: right;
    width: 90%;
    margin: 0px;
    display: inline-block;
}
table#account-numbers tbody tr td .valid-amount{
    text-align: right;
    width: 90%;
    margin: 0px;
    display: inline-block;
}
table#account-numbers .actions-dropdown button{
    border-radius: 0px;
    height: 33px;
    background-color: white;
    color: black;
    font-size: 14px;
    border-color: #dee2e6;
    width: 190px;
}
table#account-numbers .actions-dropdown .dropdown-item {
    padding-left: 7%;
}
table#account-numbers .dropdown-item i {
    margin-right: 5px;
}
table#account-numbers .actions-dropdown button:focus{
    color: black!important;
    background-color: white!important;
    border-color: #dee2e6!important;
}
table#account-numbers .dropdown-toggle::after {
    display: inline-block;
    margin-left: 3.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
table#account-numbers td .account-number {
    width: 110px;
}
/* table#account-numbers td .service-location {
    width: 265px;
} */
table#account-numbers td .amount-to-pay {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}
table#account-numbers td .due-date, table#account-numbers td .bill-date {
    width: 95px;
}
#bar-chart-modal {
    max-width: 79%;
    margin: 25px auto;
}
#bar-chart-modal #consumption-chart{
    margin: auto;
}
#bar-chart-modal #loading {
    position: relative;
    margin: auto;
    text-align: center;
    height: 300px;
}
#bar-chart-modal #loading .spinner-border {
    top: 35%;
    position: absolute;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    left: 48%;
}
#bar-chart-modal .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
}
#bar-chart-modal #bar-chart-wrap .top {
    padding: 0px 0px 15px;
}

#bar-chart-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
#my-bills-modal .modal-body {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1.5rem;
}
#my-bills-modal .my-bills-title{
    background-color: #1788c7;
    padding: 15px 30px;
}
#my-bills-modal .my-bills-title label{
    margin: 0;
}
#my-bills-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
#my-bills-modal #loading {
    position: relative;
    margin: auto;
    text-align: center;
    height: 300px;
}
#my-bills-modal #loading .spinner-border {
    top: 35%;
    position: absolute;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    left: 46%;
}
#view-bill-modal {
    max-width: 88%;
    margin: 25px auto;
}
#view-bill-modal .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
}
#view-bill-modal #loading {
    position: relative;
    margin: auto;
    text-align: center;
    height: 500px;
}
#view-bill-modal #loading .spinner-border {
    top: 35%;
    position: absolute;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    left: 48%;
}
#view-bill-modal .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
}
#view-bill-modal #bar-chart-wrap .top {
    padding: 0px 0px 15px;
}

#view-bill-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
.sub-total {
    padding: 0px 20px;
}
.sub-total button:hover {
    color: #08c484;
    background-color: white;
    border-color: #08c484;
}
div#pay-now-modal{
    max-width: 1200px;
}
section#review-order p {
    margin: 0;
}
section#review-order .section-title{
    padding-bottom: 15px;
}
section#review-order .bottom-hr{
    padding-bottom: 15px;
}
div#pay-now-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
section#credit-card-info .section-title {
    padding-bottom: 20px;
}
section#credit-card-info input[type=text] {
    height: 35px;
    border-radius: 0;
}
section#credit-card-info label {
    font-size: 14px;
}
section#credit-card-info button[type=submit]{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 190px;
}
section#payment-options {
    margin-top: 15px;
}
section#credit-card-info {
    margin-top: 30px;
}
.container-check input:checked + .checkmark {
    background-color: #2196F3;
    transition: 0.3s;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
    transition: 0.3s;
}
div#popover-contained {
    z-index: 1;
}
section#payment-content h3, section#payment-content h5 {
    font-weight: 700;
}

section#payment-content .bordered {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    max-width: 680px;
    padding: 35px;
    margin: auto;
}
section#payment-content .left-auto{
    width: 225px;
    margin: auto;
}

section#payment-content .header-left{
    border-bottom: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    padding: 15px
}

section#payment-content .header-right{
    border-bottom: 1px solid #ebebeb;
    padding: 15px
}
section#payment-content .header-left label, section#payment-content .header-right label {
    margin: 0;
}
section#payment-content label{
    display: block;
}

section#payment-content .content-left {
    border-right: 1px solid #ebebeb;
}
section#payment-content .content-left, section#payment-content .content-right{
    padding: 40px 0px;
}

section#payment-content .btn-left{
    border-color: #adadad;
    color: #666666;
    padding: 15px 55px;
    min-width: 256.14px;
}
section#payment-content .btn-right{
    border-color: #08c484;
    background-color: #08c484;
    color: #fff;
    padding: 15px 55px;
}
@media (max-width: 991px){
    table#account-numbers tbody tr td .valid-amount {
        text-align: center;
        width: 60%;
        margin: 0 25px;
    }
    .account-summary .table-overflow { 
        height: 340px;
        border: 1px solid #dee2e6;
    }
    #bill-form-container{
        overflow: scroll;
    }
    #view-bill-actions{
        position: fixed;
        z-index: 1;
        margin-bottom: 41px;
    }
    #view-bill-spacer{
        height: 75px;
    }
}
#payment-history-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
#payment-history-modal .my-bills-title {
    background-color: #1788c7;
    padding: 15px 20px;
}
#payment-history-modal {
    max-width: 500px;
    margin: 1.75rem auto;
    font-size: 14px;
}
#receipt {
    background-color: white;
    max-width: 780px;
    margin: auto;
}
#cvv-hint{
    max-width: 600px;
}
#cvv-hint .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
}

@media (min-width: 992px){
    #cvv-hint .hint-content {
        position: absolute;
        top: 30%;
        width: 270px;
    }
}
@media (max-width: 991px){
    #cvv-hint .hint-content {
        padding: 25px 15px;
    }
}

#popup-message .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0;
}
#popup-message .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    outline: 0;
}
#popover-contained {
    padding: 15px;
    padding-right: 10px!important;
}
span#card-visa-logo, span#card-discover-logo, span#card-master-logo, span#card-undefined-logo {
    background-repeat: no-repeat;
    background-position: right;
}
span#card-visa-logo {
    background-image: url(/images/visa-logo.png);
    width: 60px;
}
span#card-discover-logo {
    background-image: url(/images/discover-logo.png);
    width: 60px;
}
span#card-master-logo {
    background-image: url(/images/master-logo.png);
    width: 60px;
}
span#card-undefined-logo {
    background-image: url(/images/forbidden-mark.png);
    background-size: 20px;
    width: 37px;
}
.input-group-text {
    background-color: #fff!important;
    border: 0px!important;
    border-radius: 0px!important;
}

#paperless-billing-modal {
    max-width: 550px!important;
}

#paperless-billing-modal .modal-header{
    border-bottom: 0;
}
#paperless-billing-modal .modal-body{
    padding: 0px 40px!important;
    padding-bottom: 30px!important;
}
#paperless-billing-modal #form-actions{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
}
#paperless-billing-modal #save button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 120px;
}
#paperless-billing-modal #close-result{
    border: 1px solid #c2c1c1;
    width: 100px;
    height: 42px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 40px;
}
#paperless-billing-modal .account-info{
    margin-top: 32px;
    margin-right: 0px;
}
#paperless-billing-modal #form-actions{
    text-align: center;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 15px;
    max-width: 270px;
}
@media(max-width: 575px){
    #paperless-billing-modal .account-info {
        text-align: center;
    }
    #paperless-billing-modal .account-info p{
        margin: 0px;
    }
    #paperless-billing-modal .toggle-switch input{
        display: block;
    }
    #paperless-billing-modal .toggle-switch label{
        margin: 10px auto;
    }
}
.spinner-border {
    color: #1788c7;
}
div#my-bills-modal {
    font-size: 14px;
}

div#popup-message .modal-header img {
    width: 25px;
    margin-bottom: 5px;
}

div#popup-message .modal-body label {
    font-size: 14px;
    font-weight: 500;
}
