#dollarSign
{
    width: auto;
    height: 200px;
    left: 9px;
    top: 7px;
    z-index: 4;
    position: absolute;
    color: #495057;
}

.currencyInputsss
{
    padding-left:50px;
}