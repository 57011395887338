input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

#paperless-billing-modal div#cancel, #paperless-billing-modal div#save {
    display: inline-block;
}

#paperless-billing-modal div#cancel {
    vertical-align: middle;
    width: 120px;
    height: 42px;
    padding: 5px;
}

#paperless-billing-modal label.on:before {
    content: 'ON';
    margin-left: 15px;
    color: white;
}

#paperless-billing-modal  label.off:after {
    content: 'OFF';
    margin-left: 37px;
    color: white;
}


.customer-feedback .below-container {
    margin-top: 100px;
    margin-left: -24px;
    line-height: 2;
}

#pay-now-modal .react-datepicker__month .react-datepicker__month-text, #pay-now-modal  .react-datepicker__month .react-datepicker__quarter-text {
    width: 3rem!important; 
}
#pay-now-modal .react-datepicker__input-container input {
    width: 100%;
    padding: 0px 10px;
}
.red-font {
    color: #df0018!important;
}
a:hover {
    text-decoration: none!important;
}
#login .bottom-img {
    margin-top: 20px;
}
#dashboard .nav-links{
    padding-right: 80px;
}
#dashboard .nav-links a{
    display: inline-block;
    color: white;
    padding: 5px 20px;
}
#dashboard .nav-links a p {
    margin: 0px;
}
#dashboard .nav-main{
    padding: 13px 80px 0px;
}
#dashboard .nav-main .float-left .gpa_logo {
    height: auto;
    width: 245px;
}
#dashboard .nav-main .nav-buttons a {
    padding-top: 82px;
    padding: 0px 30px 0px 15px;
    font-size: 14px;
}
#dashboard .nav-main .nav-buttons a span{
    padding: 10px;
}

#dashboard h5{
    font-weight: 600;
}
#dashboard .main hr{
    border-top: 2px solid #cccccc;
}
#dashboard .main .survey_wrap{
    padding-bottom: 30px;
}
#dashboard .main .survey_wrap .featured_image{
    display: inline-block;
}
#dashboard .main .survey_wrap .content{
    display: inline-block;
    vertical-align: middle;
    max-width: 640px;
}
#dashboard .main .survey_wrap .content a{
    font-weight: 600;
    font-size: 12px;
}
#dashboard .customer-feedback button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 185px!important;
    padding: 10px;
    float: right;
}
#dashboard .customer-feedback button:hover {
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important;
}
#dashboard .main .edit-form{    
    max-width: 750px;
    margin: auto;
}

#dashboard .main .edit-form input{    
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form select{    
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form label{    
    font-weight: 500;
    color: #333333;
}

#dashboard .main .edit-form h5 {
    font-size: 18px;
    color: #333333;
}

#dashboard .main .edit-form .change-pass{
    padding: 0px 0px 50px 0px;
}
#dashboard .main .edit-form .change-pass a{
    font-weight: 500;
}
#dashboard .main .edit-form .change-pass .float-left h5{
    margin-top: 15px; 
}
#dashboard .main .edit-form .change-pass .float-right{
    margin-top: 5px;
    font-size: 15px;
}
#dashboard .main .edit-form .change-pass button {
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 185px!important;
    padding: 10px;
}
#dashboard .main .edit-form .paperless-billing{
    padding: 10px 25px 30px 0px;
    font-size: 15px;
}
#dashboard .main .edit-form .paperless-billing h5{
    margin: 0px;
}
#dashboard .main #title {
    max-width: 875px;
    margin: auto;
}
#dashboard .main #form-actions{
    padding: 20px 40px;
}
#dashboard .main #form-actions #save button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 185px!important;
    padding: 10px;
}

#dashboard .main #form-actions #save button:hover{
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important
}
#dashboard .main #form-actions #cancel{
    padding: 10px 0;
    text-align: right;
}
.nav-buttons{
    float: right;
}
.nav-buttons .logout{
    margin: 0px;
    color: #ababab;
    cursor: pointer;
}
#dashboard .main .account-overview{
    margin-left: 0px;
    margin: auto;
}
#dashboard .main .account-overview .bill-summary .header{
    border: 1px solid #278ec8;
    background-color: #278ec8;
    color: white;
    padding: 10px 20px;
}
#dashboard .main .account-overview p{
    font-weight: 500;
    
}

#dashboard .main .account-overview .bill-summary .header p{
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;
}
#dashboard .main .account-overview .bill-summary .header select{
    display: inline-block;
    width: 480px;
}

#dashboard .main .account-overview .bill-summary .border{
    border: 1px solid;
    border-top: 0px;
    padding: 10px;
}

#dashboard .main .account-overview .bill-summary .border-top-0{
    padding: 24px;
}

#dashboard .main .account-overview .bill-summary .border p{
    margin: auto;
}
#dashboard .main .account-overview .bill-summary .pay-now{
    padding: 40px;
}
#dashboard .main .account-overview .bill-summary .pay-now button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 100%;
}    
#dashboard .main .account-overview .bill-summary .pay-now button:hover{
    color: #08c484;
    background-color: white!important;
    border-color: #08c484!important;
} 
#dashboard .main #info-wrap{
    padding: 30px 30px;
    width: 100%;
}

#dashboard .main #info-wrap .account-info .header label{
    display: inline-block;
    margin-right: 60px;
}

#dashboard .main #info-wrap .account-info .header a{
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    float: right;
}

#dashboard .main #info-wrap .account-info .body p{
    margin: 0px;
}
#dashboard .main #info-wrap .contact-info .body p{
    margin: 0px;
}

#dashboard .main .my-bills-info p{
    margin: 0px;
}

#dashboard .main #my-bills-table .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px;
    text-align: center;
    background-color: #1788c7;
    color: white;
}
#dashboard .main #my-bills-table .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
    text-align: center;
}
/* #welcome-wrap{
    max-width: 1035px;
} */

#bar-chart-wrap {
    /* padding: 90px 250px 30px 80px; */
    margin: 15px 0px;
}

#bar-chart-wrap .top label, #bar-chart-wrap .top p, #bar-chart-wrap .top select{
    display:inline-block;
}

#bar-chart-wrap .top p{
    font-size: 14px;
    font-weight: 600;
    padding-right: 10px;
}
#bar-chart-wrap .top select{
    font-size: 14px;
}
#bar-chart-wrap .top{
    padding: 30px 0px 15px;
}
#bar-chart-wrap .bottom{
    padding: 0px;
}

#bar-chart-wrap .bottom .graph-label{
    margin: auto;
    text-align: center;
    padding-left: 45px;
}

#bar-chart-wrap .bottom .graph-label label{
    /* transform: rotate(-90deg); */
}

#bar-chart-wrap .bottom .col-lg-3{
    margin: auto;
}
#dashboard #nav-mobile{
    padding: 25px 25px;
}    
#dashboard #nav-mobile #basic-navbar-nav {
    padding: 25px 10px 10px;
}
#dashboard #nav-mobile #basic-navbar-nav .mr-auto.navbar-nav a {
    margin: 5px 0px;
}
#dashboard #nav-mobile .navbar-brand .gpa_logo{
    height: auto;
    width: 215px;
}
div#responsive-navbar-nav {
    padding: 25px;
}
.mr-auto.navbar-nav a{
    color: white;
}
#dashboard .main #info-wrap .account-info hr, #dashboard .main #info-wrap .contact-info hr  {
    margin-top: 0px;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#loading{
    position: relative;
    margin: auto;
    text-align: center;
    height: 100vh;
}
#loading .spinner-border {
    top: 25%;
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: text-bottom;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
section#dashboard .nav-main .nav-logo{
    float: left;
}
section#dashboard .nav-main .nav-buttons {
    float: right;
    height: 60px;
    padding: 20px 0px;
}
#dashboard .main .account-overview .bill-summary .header input{
    padding: 1px 5px;
    pointer-events: none;
    border-radius: 5px;
    width: 500px;
}
.navbar-toggler {
    float: right;
}
#dashboard .main #form-actions #save, #dashboard .main #form-actions #cancel{
    text-align: center;
}
.nav-buttons .logout:hover {
    text-decoration: none;
}

@media (max-width: 575px){
    #dashboard .main {
        padding: 10px 25px;
    }
}
@media (max-width: 360px){
    #dashboard #footer-main .float-left p{
        font-size: 3.5vw;
    }
    #dashboard .main .survey_wrap {
        padding-bottom: 30px;
        text-align: center;
    }
    #dashboard #nav-mobile .navbar-brand .gpa_logo {
        height: auto;
        width: 185px;
    }
    #dashboard .main #welcome-wrap h3{
        font-size: 7.5vw;
    }
}
@media (max-width: 420px){
    #dashboard .main #info-wrap{
        bottom: -45px!important; 
    }
    
    #dashboard .main #info-wrap .account-info .header a{
        margin: 10px 0px;
        float: none;
    }
}
@media (min-width: 421px) and (max-width: 501px){
    #dashboard .main #info-wrap{
        bottom: -10px!important;
    }
    
}
@media (max-width: 570px){
    #dashboard .main .account-overview .bill-summary .header{
        text-align: center;
    }
    #dashboard .main .account-overview .bill-summary .header input{
        width: 100%!important;
    }
}

@media (max-width: 674px){
    #dashboard #footer-main .float-left{
        float: none!important;
        padding-left: 0!important;
    }
    #dashboard #footer-main .float-right{
        float: none!important;
        padding-right: 0px!important;
    }
}
@media (min-width: 992px){
    #nav-desktop{
        display: block;
    }
    #nav-mobile{
        display: none;
    }
    #dashboard .main #form-actions #cancel {
        text-align: right;
    }
    #survey-list select, #survey-list textarea{
        margin: 10px 0px
    }
    /* #survey-list .required-fields, #survey-list .not-required-field{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 315px;
    } */
    #welcome-wrap .account-number{
        float: right;
        margin: 15px 0px;
    }
    .text-right-lg{
        text-align: right
    }
}
@media (max-width: 480px){
    #dashboard .main .edit-form .paperless-billing {
        padding: 10px 25px 10px 0px;
        font-size: 15px;
    }
    #dashboard .main .edit-form .paperless-billing .float-left {
        float: none!important;
    }
    #dashboard .main .edit-form .paperless-billing .float-right {
        float: none!important;
        margin-top: 10px;
    }
}
@media (max-width: 991px){
    #nav-desktop{
        display: none;
    }
    #nav-mobile{
        display: block;
    }
    #bar-chart-wrap .bottom{
        text-align: center;
    }
    #bar-chart-wrap .bottom .graph-label{
        padding: 20px 0px 20px 0px!important;
    }
    #bar-chart-wrap .bottom .graph-label label{
        transform: rotate(0deg);
    }
    #dashboard .main #info-wrap{
        bottom: 15px;
    }
    #dashboard #footer-main{
        bottom: -50px;
    }
    #dashboard .main .account-overview .bill-summary .header select{
        width: 100%!important;
        text-align: center;
    }
    section#dashboard .nav-main .nav-buttons {
        float: none;
        text-align: center;
    }
    section#dashboard .nav-main .nav-logo {
        float: none;
        text-align: center;
    }
    #dashboard .nav-links {
        padding: 0px;
        text-align: center;
    }
    #loading .spinner-border{
        left: 44%
    }
    #bar-chart-wrap .scroll-hidden-mobile{
        width: 100%;
        padding: 0px;
        margin: 0px 30px 0px 30px;
        overflow-y: hidden;
    }
    #bar-chart-wrap #bar-scroll-mobile{
        overflow-y: scroll;
        width: 650px;
    }
    #bar-chart-wrap .top {
        padding: 30px 0px 0px;
    }
    #survey-list select, #survey-list textarea{
        margin: 10px 0px
    }
}
#survey-list .thank-you-msg h5{
    font-weight: 400!important;
    font-style: italic;
}
#survey-list button{
    background-color: #08c484!important;
    border-color: #08c484!important;
    width: 150px!important;
    padding: 10px; 
    float: right;
}

#survey-list select, #survey-list textarea{
    margin-right: 15px;
    border-radius: 5px;
    border: 2px solid #cccccc;
}
#survey-list .btn-wrap {
    margin: 30px 0px;
}

#survey-list .thank-you-msg {
    margin: 40px 0px;
}

button .spinner-border-sm {
    margin-bottom: 1px;
    margin-right: 5px;
}

#survey-list .btn-wrap button {
    margin: 0px 0px 40px;
}
#dashboard .main .customer-feedback input, #dashboard .main .customer-feedback select, #dashboard .main .customer-feedback textarea {
    border-radius: 5px;
    border: 2px solid #cccccc;
}
div#my-bills-table td p {
    margin: 0;
    cursor: pointer;
}
#dashboard .main {
    background-color: white;
}
#view-bill {
    width: 1140px;
    height: auto;
    border: solid 1px black;
}
#view-bill .bill-template {
    position: relative;
}
#view-bill .bill-template img {
    width: 100%;
}
#view-bill .bill-template .charges-info{
    position: absolute;
    top: 22.2%;
}
p.charges-info.prevBalance {
    left: 6%;
    width: 11.5%;
    text-align: center;
}
p.charges-info.amountPaid {
    left: 20%;
    width: 10%;
    text-align: center;
}
p.charges-info.adjustments {
    left: 30.5%;
    width: 13%;
    text-align: center;
}
p.charges-info.corrections {
    left: 43%;
    width: 13.5%;
    text-align: center;
}
p.charges-info.totalAmountDue {
    left: 69%;
    width: 12%;
    text-align: center;
}
p.charges-info.amountDueBy {
    left: 80.5%;
    width: 14.5%;
    text-align: center;
}
p.charges-info.currCharges {
    left: 57%;
    width: 10%;
    text-align: center;
}
#view-bill .bill-template .consumption-details-left {
    position: absolute;
    top: 27.8%;
    left: 20%;
}
#view-bill .bill-template .consumption-details-right {
    position: absolute;
    top: 28%;
    left: 55.8%;
}
#view-bill .bill-template .top-account-info {
    position: absolute;
    top: 8%;
    left: 71%;
}
#view-bill .bill-template .bill-segment{
    position: absolute;
    top: 42%;
    left: 6%;
    right: 6%;
}
#view-bill .bill-template .bill-message{
    background-color: yellow;
    border: 1px solid;
    height: 50px;
    width: 88.2%;
    position: absolute;
    top: 60%;
    left: 6%;
    right: 6%;
    font-size: 14px;
    text-align: center;
    padding: 12px;
    font-weight: 700;
}
#view-bill .bill-template .bottom-bill-info{
    position: absolute;
    top: 80.6%;
    left: 80%;
}
#view-bill .bill-template .bottom-bill-info p {
    margin: 1% 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .bottom-acc-info{
    position: absolute;
    top: 90%;
    left: 31.5%;
}

#view-bill .bill-template .bottom-acc-info p{
    margin: 0;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 500;
}
#view-bill .bill-template canvas{
    position: absolute;
    width: 12%
}
#view-bill .bill-template .barcode{
    position: absolute;
    top: 80.2%;
    left: 55%;
}
#view-bill .bill-template .barcode img{

}
#view-bill .bill-template .bar-chart{
    width: 21%;
    height: 11%;
    position: absolute;
    top: 26%;
    left: 72.5%;
}
#view-bill .bill-template .bar-chart p {
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    margin: 2% 0;
}

#view-bill .bill-template .barcode-img{
    /* position: absolute; */
    top: 80.2%;
    left: 54%;
    width: 12%;
}
#view-bill .bill-template .billing-period {
    position: absolute;
    top: 17%;
    left: 75%;
}
#view-bill .bill-template .date-applied {
    position: absolute;
    top: 17%;
    left: 34%;
}
#view-bill .bill-template .billing-period p, #view-bill .bill-template .date-applied p {
    margin: 0 0 11% 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .bill-segment .calcLine {
    margin: 0 3%;
}

#view-bill .bill-template .bill-segment p {
    margin: 0;
    display: inline-block;
}
#view-bill .bill-template .bill-segment .bill-segment-content .calcLine span {
    margin-right: 15%;
}
#view-bill .bill-template .bill-segment .bill-segment-content p, #view-bill .bill-template .bill-segment .bill-segment-content span {
    font-family: sans-serif;
    font-size: 14px;
}


#view-bill-actions .download, #view-bill-actions .back{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    margin-bottom: 10px
}
#view-bill-actions .download{
    float: right;
}
#view-bill .bill-template .top-account-info p{
    margin: 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .charges-info{
    margin: 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .consumption-details-left p {
    margin: 0 0 7% 0;
    font-family: sans-serif;
    font-size: 14px;
}
#view-bill .bill-template .consumption-details-right p {
    margin: 0 0 11% 0;
    font-family: sans-serif;
    font-size: 14px;
}
.overlay{
    background-color:#ccc;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}
#account-numbers .table-hover tbody tr:hover {
    background-color: #76B6E4!important;
}
.sign-up-result .text-red {
    color: #df0018!important;
}
table#account-numbers {
    margin: 0;
}
table#account-numbers thead tr th {
    background-color: #e3e5e7;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: sticky;
    top: 0;
    padding: 12px
}
table#account-numbers td {
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
}
table#account-numbers td div {
    margin:auto
}
div#scrollable-container{
    overflow: auto;
    height: 155px;
}
.account-summary .table-overflow{
    height: 340px;
    border: 1px solid #dee2e6;
}
table#account-numbers .highlighted{
    background-color: #e6f2f9;
    transition: 0.3s;
}
table#account-numbers .checkmark{
    position: absolute;
    left: 5px;
    height: 22px;
    width: 22px;
    top: -20px;
}

.account-summary .account-summary-label{
    background-color: #1788c7;
    padding: 15px 25px;
}
.account-summary .account-summary-label label{
    margin: 0;
}

.sub-total button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 150px;
}

.fixed_header tbody{
    display:block;
    overflow:scroll;
    height:200px;
    width:100%;
}
.fixed_header thead tr{
    display:block;
}


.dollar-sign{
    width:9%;
    vertical-align: middle;
    margin:auto;
    display: inline-block;
}
table#account-numbers td{
    text-align: center;
}
table#account-numbers tbody tr td .invalid-amount{
    text-align: right;
    width: 90%;
    margin: 0px;
    display: inline-block;
}
table#account-numbers tbody tr td .valid-amount{
    text-align: right;
    width: 90%;
    margin: 0px;
    display: inline-block;
}
table#account-numbers .actions-dropdown button{
    border-radius: 0px;
    height: 33px;
    background-color: white;
    color: black;
    font-size: 14px;
    border-color: #dee2e6;
    width: 190px;
}
table#account-numbers .actions-dropdown .dropdown-item {
    padding-left: 7%;
}
table#account-numbers .dropdown-item i {
    margin-right: 5px;
}
table#account-numbers .actions-dropdown button:focus{
    color: black!important;
    background-color: white!important;
    border-color: #dee2e6!important;
}
table#account-numbers .dropdown-toggle::after {
    display: inline-block;
    margin-left: 3.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
table#account-numbers td .account-number {
    width: 110px;
}
/* table#account-numbers td .service-location {
    width: 265px;
} */
table#account-numbers td .amount-to-pay {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}
table#account-numbers td .due-date, table#account-numbers td .bill-date {
    width: 95px;
}
#bar-chart-modal {
    max-width: 79%;
    margin: 25px auto;
}
#bar-chart-modal #consumption-chart{
    margin: auto;
}
#bar-chart-modal #loading {
    position: relative;
    margin: auto;
    text-align: center;
    height: 300px;
}
#bar-chart-modal #loading .spinner-border {
    top: 35%;
    position: absolute;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    left: 48%;
}
#bar-chart-modal .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
}
#bar-chart-modal #bar-chart-wrap .top {
    padding: 0px 0px 15px;
}

#bar-chart-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
#my-bills-modal .modal-body {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1.5rem;
}
#my-bills-modal .my-bills-title{
    background-color: #1788c7;
    padding: 15px 30px;
}
#my-bills-modal .my-bills-title label{
    margin: 0;
}
#my-bills-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
#my-bills-modal #loading {
    position: relative;
    margin: auto;
    text-align: center;
    height: 300px;
}
#my-bills-modal #loading .spinner-border {
    top: 35%;
    position: absolute;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    left: 46%;
}
#view-bill-modal {
    max-width: 88%;
    margin: 25px auto;
}
#view-bill-modal .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
}
#view-bill-modal #loading {
    position: relative;
    margin: auto;
    text-align: center;
    height: 500px;
}
#view-bill-modal #loading .spinner-border {
    top: 35%;
    position: absolute;
    border: 7px solid #1788c7;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    left: 48%;
}
#view-bill-modal .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
}
#view-bill-modal #bar-chart-wrap .top {
    padding: 0px 0px 15px;
}

#view-bill-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
.sub-total {
    padding: 0px 20px;
}
.sub-total button:hover {
    color: #08c484;
    background-color: white;
    border-color: #08c484;
}
div#pay-now-modal{
    max-width: 1200px;
}
section#review-order p {
    margin: 0;
}
section#review-order .section-title{
    padding-bottom: 15px;
}
section#review-order .bottom-hr{
    padding-bottom: 15px;
}
div#pay-now-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
section#credit-card-info .section-title {
    padding-bottom: 20px;
}
section#credit-card-info input[type=text] {
    height: 35px;
    border-radius: 0;
}
section#credit-card-info label {
    font-size: 14px;
}
section#credit-card-info button[type=submit]{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 190px;
}
section#payment-options {
    margin-top: 15px;
}
section#credit-card-info {
    margin-top: 30px;
}
.container-check input:checked + .checkmark {
    background-color: #2196F3;
    transition: 0.3s;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
    transition: 0.3s;
}
div#popover-contained {
    z-index: 1;
}
section#payment-content h3, section#payment-content h5 {
    font-weight: 700;
}

section#payment-content .bordered {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    max-width: 680px;
    padding: 35px;
    margin: auto;
}
section#payment-content .left-auto{
    width: 225px;
    margin: auto;
}

section#payment-content .header-left{
    border-bottom: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    padding: 15px
}

section#payment-content .header-right{
    border-bottom: 1px solid #ebebeb;
    padding: 15px
}
section#payment-content .header-left label, section#payment-content .header-right label {
    margin: 0;
}
section#payment-content label{
    display: block;
}

section#payment-content .content-left {
    border-right: 1px solid #ebebeb;
}
section#payment-content .content-left, section#payment-content .content-right{
    padding: 40px 0px;
}

section#payment-content .btn-left{
    border-color: #adadad;
    color: #666666;
    padding: 15px 55px;
    min-width: 256.14px;
}
section#payment-content .btn-right{
    border-color: #08c484;
    background-color: #08c484;
    color: #fff;
    padding: 15px 55px;
}
@media (max-width: 991px){
    table#account-numbers tbody tr td .valid-amount {
        text-align: center;
        width: 60%;
        margin: 0 25px;
    }
    .account-summary .table-overflow { 
        height: 340px;
        border: 1px solid #dee2e6;
    }
    #bill-form-container{
        overflow: scroll;
    }
    #view-bill-actions{
        position: fixed;
        z-index: 1;
        margin-bottom: 41px;
    }
    #view-bill-spacer{
        height: 75px;
    }
}
#payment-history-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
#payment-history-modal .my-bills-title {
    background-color: #1788c7;
    padding: 15px 20px;
}
#payment-history-modal {
    max-width: 500px;
    margin: 1.75rem auto;
    font-size: 14px;
}
#receipt {
    background-color: white;
    max-width: 780px;
    margin: auto;
}
#cvv-hint{
    max-width: 600px;
}
#cvv-hint .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
}

@media (min-width: 992px){
    #cvv-hint .hint-content {
        position: absolute;
        top: 30%;
        width: 270px;
    }
}
@media (max-width: 991px){
    #cvv-hint .hint-content {
        padding: 25px 15px;
    }
}

#popup-message .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0;
}
#popup-message .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    outline: 0;
}
#popover-contained {
    padding: 15px;
    padding-right: 10px!important;
}
span#card-visa-logo, span#card-discover-logo, span#card-master-logo, span#card-undefined-logo {
    background-repeat: no-repeat;
    background-position: right;
}
span#card-visa-logo {
    background-image: url(/images/visa-logo.png);
    width: 60px;
}
span#card-discover-logo {
    background-image: url(/images/discover-logo.png);
    width: 60px;
}
span#card-master-logo {
    background-image: url(/images/master-logo.png);
    width: 60px;
}
span#card-undefined-logo {
    background-image: url(/images/forbidden-mark.png);
    background-size: 20px;
    width: 37px;
}
.input-group-text {
    background-color: #fff!important;
    border: 0px!important;
    border-radius: 0px!important;
}

#paperless-billing-modal {
    max-width: 550px!important;
}

#paperless-billing-modal .modal-header{
    border-bottom: 0;
}
#paperless-billing-modal .modal-body{
    padding: 0px 40px!important;
    padding-bottom: 30px!important;
}
#paperless-billing-modal #form-actions{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
}
#paperless-billing-modal #save button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 120px;
}
#paperless-billing-modal #close-result{
    border: 1px solid #c2c1c1;
    width: 100px;
    height: 42px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 40px;
}
#paperless-billing-modal .account-info{
    margin-top: 32px;
    margin-right: 0px;
}
#paperless-billing-modal #form-actions{
    text-align: center;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 15px;
    max-width: 270px;
}
@media(max-width: 575px){
    #paperless-billing-modal .account-info {
        text-align: center;
    }
    #paperless-billing-modal .account-info p{
        margin: 0px;
    }
    #paperless-billing-modal .toggle-switch input{
        display: block;
    }
    #paperless-billing-modal .toggle-switch label{
        margin: 10px auto;
    }
}
.spinner-border {
    color: #1788c7;
}
div#my-bills-modal {
    font-size: 14px;
}

div#popup-message .modal-header img {
    width: 25px;
    margin-bottom: 5px;
}

div#popup-message .modal-body label {
    font-size: 14px;
    font-weight: 500;
}