@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#IEpage .secondary-font-color {
  color: #1788c7!important;
}
#IEpage .font-bold {
  font-weight: 600;
}
#IEpage #logo-wrap{
  margin: 40px 0px 35px;
}
#IEpage #content-wrap{
  background-color: #f3f6f7;
  height: 100%;
}
#IEpage #info-wrap{
  text-align: center;
  padding: 40px 0px;
}
#IEpage #info-wrap p{
  font-size: 16px;
}
#IEpage #compatible-browser-wrap{
  text-align: center;
}
#IEpage #compatible-browser-wrap .browser-type{
  width: 230px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
}
#IEpage #compatible-browser-wrap .browser-type .browser-logo, #IEpage #compatible-browser-wrap .browser-type .browser-name{
  display: block;
}
#IEpage #compatible-browser-wrap .browser-type .browser-logo{
  margin: auto;
}
#IEpage #compatible-browser-wrap .browser-type .browser-name{
  text-align: center;
}
#IEpage #specs-info-wrap{
  margin: 50px auto;
}
#IEpage #specs-info-wrap .header-wrap {
  background-color: #1788c7!important;
  padding: 10px 20px;
  max-width: 900px;
  margin: auto;
}
#IEpage #specs-info-wrap .header-wrap h5{
  margin: 0;
  color: #fff;
  font-weight: 600;
}
#IEpage #specs-info-wrap .body-wrap {
  max-width: 900px;
  margin: auto;
}
@media (max-width: 1200px){
    #IEpage .container {
      max-width: 1140px;
  }
}

@media (max-width: 992px){
  #IEpage .container {
    max-width: 960px;
  }
}

@media (max-width: 768px){
  #IEpage .container {
    max-width: 720px;
  }
}

@media (max-width: 576px){
  #IEpage .container {
    max-width: 540px;
  }
}
#IEpage .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
#IEpage .column-1, #IEpage .column-2{
  padding: 20px;
  font-size: 14px;
}
#IEpage .column-1 {
  float: left;
  width: 26%;
  background-color: #eff2f3;
}
#IEpage .column-2 {
  float: left;
  width: 74%;
}
#IEpage .column-1, #IEpage .column-2{
  border-bottom: 1px solid #dee1e2;
}
/* Clear floats after the columns */
#IEpage .row:after {
  content: "";
  display: table;
  clear: both;
}
#IEpage .row{
  margin:0!important
}