
.button-inblock {
  display: inline-block;
  left: 10px;
  top: -4px;
  position: relative;
}

div#report-list-container tbody tr td:nth-child(7), #modal-for-individual tbody tr td:nth-child(3)
{
  text-align: right;
}
#web-pay-history-table tbody tr td:nth-child(6), #payment-history-complete tbody tr td:nth-child(3){
  text-align: right;
}
#admin-control-login .container {
  position: relative;
  top: 10%;
}

#admin-reports-container .csv-container {
  float: right;
  margin: 20px 0;
}

#admin-reports-container a.btn-export-csv {
  color: #fff;
  background-color: #08c484;
  border: 1px solid #08c484;
  border-radius: 5px;
  font-size: 14px;
  padding: 12px 25px;
  font-weight: 500!important;
}

#admin-reports-container a.btn-export-csv:hover{
    color: #08c484!important;
    background-color: #fff!important;
    border-color: #08c484!important;
}


#admin-reset-pass-container {
  padding: 35px 30px;
}

div#report-list-container thead tr th, div#report-list-container tbody tr td {
  vertical-align: middle;
}
div#step1-cont .check-container {
  margin-top: 15px;
}
#admin-control .nav-tabs a {
  color: black;
}
#admin-control .container-check {
  padding-left: 30px;
  margin-bottom: 10px;
}

.rdw-editor-main {
  height: 200px;
  border: 1px solid #F1F1F1;
  padding: 0px 10px;
}

#admin-control table {
  table-layout: auto!important;
}
@media (max-width: 768px){
  #admin-announcement-container .button-right, #admin-announcement-container .button-left {
    margin-top: 10px;
  }
  #admin-control #container-top {
    padding: 10px 30px;
  }
} 
@media (max-width: 425px){
  #admin-control #container-top {
    padding: 10px 30px;
  }
  #admin-control .nav-link {
    width: 100%!important;
  }
  #admin-add-user-section .view-user-container.col {
    position: relative;
    margin-top: 30px;
    padding: 30px 10px;
  }
  #admin-reports-container .search-container-right {
    margin-left: 0px;
  }
  #admin-phonedin-container .phonedin-search-cont .input-cont-right, #admin-phonedin-container  .phonedin-search-cont .button-cont-right {
    margin-left: 0px;
  }
}
#modal-with-file .select-type label {
  margin-right: 10px;
}
#admin-phonedin-container .subtotal-cont-right {
  text-align: right;
}

#admin-phonedin-container .table-summary-cont p {
  margin-top: 30px;
}

#admin-phonedin-container .add-left img {
  margin-left: 8px;
}

#admin-phonedin-container .defaultInput {
  margin-top: -12px;
}

#admin-phonedin-container .input-cont-right {
  /* max-height: 140px; */
  overflow-y: auto;
}

#admin-phonedin-container .button-cont-right {
  vertical-align: top;
}

#admin-phonedin-container .input-cont-left{
  vertical-align: top;
}

#admin-phonedin-container .input-left-search, #admin-phonedin-container .add-left {
  display: inline-block;
}

#admin-reports-container .total-cont {
  margin-top: 30px;
}

#admin-reports-container .total-left p, #admin-reports-container .total-right p {
  margin-bottom: 0px;
  font-weight: 500;
}

#admin-reports-container .total-left, #admin-reports-container .total-right {
  display: inline-block;
  width: 50%;
}

.total-left label, .total-right label {
  width: 60%;
}


.nav-tabs .nav-link {
  border: 0.5px solid #dee2e6!important;
  border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}

.shopping-button-cont {
  padding-bottom: 60px;
}

.button-shopping-cancel button:hover {
  color: #333!important;
}

.button-shopping-cancel button {
  border: 0px!important;
  background-color: #fff;
  color: #b4b4bd;
}

.table-shopping-cont tr th {
  padding: 10px 25px;
}

.table-shopping-cont tr.with-style {
  background-color: #ECECEC;
  font-weight: 500;
}

.table-shopping-cont td.with-outline {
  border-right: 1px solid #cccccc;
  font-size: 15px;
}

#step2-cont .shopping-button-cont-left {
  float: left;
}

#step2-cont .shopping-button-cont-right {
  float: right;
}

#step2-cont .table-shopping-cont table {
   margin-top: 30px;
   border: 1px solid #dee2e6!important;
}

#step2-cont .table-shopping-cont {
  border-top: 1px solid #dee2e6!important;
  padding-top: 30px;
  margin-top: 30px;
}

#step2-cont .details-button-cont {
  margin-top: 30px;
}

.details-button-cont .button-details-continue, .details-button-cont .button-details-back, .shopping-button-cont .button-shopping-continue, .shopping-button-cont .button-shopping-cancel {
  display: inline-block;
}

.details-summary-cont {
  margin-top: 20px;
}

.details-summary-cont .table td, .table th {
  border-top: 0px solid #dee2e6!important;
  padding: 5px 0px;
}

#modal-for-individual-reports .details-summary-cont .table td, .table th {
  border-top: 0px solid #dee2e6!important;
  padding: 5px 10px;
  font-weight: 600;
}

#modal-for-individual-reports .modal-header , #modal-for-phonedin-payment .modal-header{
  border-bottom: 0px solid #dee2e6!important;
}

#modal-for-individual-reports .modal-body, #modal-for-phonedin-payment .modal-body {
  margin-top: -25px;
}

#modal-for-individual-reports h5{
  border-bottom: 1px solid #dee2e6!important;
  padding-bottom: 20px;
  
}

.table-summary-cont td.with-outline a {
  color: #007bff!important;
  cursor: pointer;
  font-weight: 600;
}

#modal-for-phonedin-payment .react-bootstrap-table table {
  table-layout: auto;
  margin-top: 0px;
}

#modal-for-phonedin-payment table th {
  background-color: #1788C7;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

#modal-for-individual-reports .td-style-first {
  font-weight: 500!important;
}

.phonedin-search-cont .top-cont {
  margin-top: 10px;
}

#modal-for-individual-reports .btn-submit-change-ok, #modal-for-phonedin-payment .btn-submit-change-ok {
  text-align: right;
}

#modal-for-phonedin-payment p {
  font-weight: 500;
}

#modal-for-individual-reports button.btn.btn-change-ok.font-bold.btn-primary, #modal-for-phonedin-payment button.btn.btn-change-ok.font-bold.btn-primary {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 110px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin: 10px 0px;
}

.add-to-cart-cont .button-cart-cancel button:hover, .button-details-back button:hover, .button-shopping-back button:hover {
  color: #fff!important;
  background-color: #dee2e6!important;
  border-color: #dee2e6!important;
}

.button-shopping-back button {
  color: #000;
  background-color: #fff;
  border-color: #dee2e6;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
}

.add-to-cart-cont .button-cart-cancel button, .button-details-back button{
  color: #000;
  background-color: #fff;
  border-color: #dee2e6;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
  margin-left: 10px;
}

.add-to-cart-cont .button-cart-add button, .button-details-continue button, .button-shopping-continue button  {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
}

.add-to-cart-cont .button-cart-add, .add-to-cart-cont .button-cart-cancel {
  display: inline-block;
}

.table-summary-cont tr th{
  padding: 10px 25px;
}

.table-summary-cont table {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.table-summary-cont td.with-outline {
  border-right: 1px solid #cccccc;
  font-size: 15px;
}

.table-summary-cont .with-style {
  background-color: #ECECEC;
  font-weight: 500;
}

#admin-phonedin-container .phonedin-search-cont {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 25px 0px 40px;
}

.phonedin-search-cont .input-cont-right, .phonedin-search-cont .button-cont-right {
  margin-left: 25px;
}

.button-cont-right button.btn.btn-change-ok.font-bold.btn-primary {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 120px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 22px;
}

#admin-phonedin-container .input-cont-left, #admin-phonedin-container .input-cont-right , #admin-phonedin-container .button-cont-right {
  display: inline-block;
}

.phonedin-search-cont select, .phonedin-search-cont input {
  display: block;
  /* width: 100%; */
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

.table-users-container {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

#admin-announcement-container .announce-container {
  padding: 10px;
}

.search-container input , .search-container select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#modal-for-individual .react-bootstrap-table, #modal-for-individualtranslog .react-bootstrap-table{
  padding: 10px;
}

#modal-for-individual .modal-body, #modal-for-individualtranslog .modal-body{
  margin-top: -25px;
}

#modal-for-individual p {
  font-weight: 600;
  margin-bottom: 20px;
}

.search-container .btn-change-ok{
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 120px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  margin-top: 5px;
}


#admin-control .container {
  max-width: 1180px;
}

#admin-control .nav-tabs {
  border-bottom: 0px solid #dee2e6; 
}

#admin-announcement-container .item-cont a {
  color: #007bff;
  cursor: pointer;
  font-weight: 600;
}

#admin-control .tab-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 15px;
}

.announcement-login .top-cont img {
  margin: 0 auto;
}

.announcement-login .cont-left h6 {
  text-align: center;
}

#announcement-container .table td, #announcement-container  .table th {
  border-top: 0px solid #dee2e6!important;
  vertical-align: middle;
}

.announcement-login a {
  cursor: pointer;
}

.announce-container a, .click-a {
  cursor: pointer;
}

#modal-announce .modal-body {
  text-align: center;
  margin-top: -25px;
  margin-bottom: 20px;
}

#modal-announce .modal-header {
  border-bottom: 0px solid #dee2e6!important;
}

.announcement-login a iframe {
  width: 200px;
  height: 150px;
  pointer-events: none;
}

.announce-container td a iframe {
  width: 100px;
  height: 80px;
  pointer-events: none;
}

#modal-announce .iframe-cont iframe {
  width: 500px;
  height: 300px;
}

#modal-with-prompt .featured-iframe iframe {
  width: 500px;
  height: 300px;
}
.announcement-login td .top-cont .featured-iframe iframe {
  width: 500px;
  height: 300px;
  text-align: center;
}

.announcement-login td .top-cont .featured-iframe  {
  text-align: center;
}

.iframe-click {
  cursor: pointer;
  background: rgba(0,0,0,0);
  width: 200px;
  height: 150px;
}

#admin-announcement-container .body-cont {
  margin-top: 10px;
}

#system-pop-announcement-container {
  margin-top: -15px;
  padding-bottom: 20px;
}

#system-pop-announcement-container .title-cont h5 {
  text-align: center;
  padding: 20px 0px 10px;
  color: #1788c7!important;
  font-weight: 600;
}

.input-cont label {
  font-weight: 500;
}

#report-list-container {
  margin-top: 20px;
}

#admin-reports-container, #admin-phonedin-container {
  padding: 35px 30px;
}

.search-container .input-cont {
  margin-bottom: 10px;
}

.search-container-right  {
  margin-left: 20px;   
}

.search-container-left, .search-container-right {
  display: inline-block;
}

#report-list-container .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

#report-list-container .btn-secondary, #report-list-container .btn-secondary:hover {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff!important;
  background-color: #1788C7!important;
  border-color: #1788C7!important;
}

.page-item.active .page-link {
  background-color: #1788C7;
  border-color: #1788C7;
}

#admin-control thead, #modal-for-individual thead, #modal-for-individualtranslog thead {
  background-color: #1788C7;
  color: #fff;
}

#modal-for-individual .modal-header, #modal-for-individualtranslog .modal-header {
  border-bottom: 0px solid #dee2e6!important;
}

#modal-for-individual .btn-change-ok , #modal-for-individualtranslog .btn-change-ok{
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 110px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  float: right;
  margin: 10px 0px;
}
#phonedIn-section #step2-cont .checkmark {
  top: -10px;
}
#report-list-container .pagination {
  float: right;
}

#report-list-container td, #modal-for-individual td,#modal-for-individualtranslog td {
  font-size: 14px;
  padding: 8px;
}
#modal-for-individualtranslog .modal-content{
  /* width: 1366px; */
}

#modal-for-individualtranslog{
  margin: 10px;
  max-width: 100%;
}
#modal-for-individualtranslog td{
  text-align: left;
}
#report-list-container .react-bootstrap-table table, #modal-for-individual .react-bootstrap-table table, #modal-for-individualtranslog .react-bootstrap-table table{
  table-layout: auto!important;
}
#report-list-container tbody tr td a {
  color: #007bff;
  cursor: pointer;
  font-weight: 600;
}

#report-list-container .table thead th, #modal-for-individual .table thead th, #modal-for-individualtranslog .table thead th{
  font-size: 13px;
  text-align: center;
}

.logout-cont {
  text-align: right;
  font-size: 14px;
}

#header-drop-admin .dropdown-menu.show {
  top: 40px!important;
  left: 15px!important;
}

#header-drop-admin .dropdown-toggle::before {
  content: none!important;
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
}

.dropdown-menu span , .dropdown-menu span {
  margin-left: 16px;
}

#header-drop-admin .content-left , #header-drop-admin .content-right {
  display: inline-block;
  margin-right: -10px;
}

#modal-for-alert h6{
  margin: -15px 0px 25px;
}

#modal-for-alert .modal-content {
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
}

#modal-for-alert .modal-body {
  text-align: center;
}

#modal-for-alert .btn-submit-change-ok {
  margin: 0px 0px 5px;
}

#modal-for-alert .btn-change-ok {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 110px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}

button:focus, table:focus, th:focus, td:focus {
  outline: none!important;
  box-shadow: none!important;
}

.btn-change-pass:hover, .btn-change-ok:hover{
  color: #08c484!important;
  background-color: #fff!important;
  border-color: #08c484!important;
}

#modal-change-pass .btn-submit-change-pass{
  float: right;
  margin: 20px 0;
}

#modal-change-pass h4 {
  margin-top: -10px;
  margin-bottom: 30px;
}

#modal-change-pass .modal-header, #modal-for-alert .modal-header {
  border-bottom: 0px solid #dee2e6!important;
  padding: 10px;
}

#modal-change-pass .modal-body {
  padding: 0px 40px;
}

#modal-change-pass .modal-content {
  width: 70%;
  margin: 0 auto;
}

#modal-change-pass .btn-change-pass, #modal-for-alert .btn-change-pass {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}

#header-drop-admin .btn-secondary {
  color: #000!important;
  background-color: transparent!important;
  border-color: transparent!important;
}

#header-drop-admin .btn-secondary:hover {
  color: #000!important;
  background-color: transparent!important;
  border-color: transparent!important;
}

#header-drop-admin .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "\2699";
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
  font-size: 25px;
}

.isDisabled-admin {
    background-color: #CBCBCB!important;
    border-color: #CBCBCB!important;
    color: #fff!important;
    pointer-events: none;
}

#announcement-container .slick-list {
  height: 600px!important;
  max-height: 800px;
}

#system-pop-announcement-container .featured-iframe {
  text-align: center;
}

#modal-with-prompt .modal-body {
  padding: 0px 40px;
}

#system-pop-container .syst {
  padding: 0px 0px 20px;
}

#system-pop-container {
  margin-top: -15px;
}

#modal-with-prompt .modal-header {
  border-bottom: 0px solid #dee2e6!important;
}

#system-pop-container .syst h5 {
  color: #1788c7!important;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
}
#system-pop-container .btn-primary {
  background-color: #08C585;
  border-color: #08C585;
  width: 150px;
  margin-top: 20px;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0px;
  width: 20px;
  height: 20px;
  content: '\2022';
  text-align: center;
  opacity: 2.25;
  border: 1px solid #fff;
  border-radius: 63px;
  color: transparent;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 2.25;
}

#announcement-container .cont-left {
  margin-top: 20px;
}

.table td, .table th {
  border-top: 1px solid #dee2e6!important;
}

#announcement-container h6{
  color: #fff;
  /* text-transform: uppercase; */
  font-weight: 600;
}

#announcement-container h5, #announcement-container p {
  color: #fff;
  text-align: left;
}

#announcement-container button.slick-arrow {
  display: none!important;
}
#announcement-container .with-announcement {
  border-bottom: 1px solid #fff;
  margin-bottom: 40px
}
#announcement-container {
  padding: 50px;
}
.disabled-front {
  background-color: #CBCBCB!important;
  border-color: #CBCBCB!important;
  color: #fff!important;
  pointer-events: none;
}
#modal-with-file .modal-header {
  border-bottom: 0px solid #dee2e6!important;
  padding: 10px;
}
#modal-with-file h4 {
  padding-bottom: 20px;
}
p.no-data {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
button.btn.btn-announcement-disabled {
  color: #fff;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
  width: 230px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
  pointer-events: none;
}
#modal-with-file .custom-file-label{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#tabs-containers {
  margin-top: 30px;
}
#modal-with-file .btn-submit-with-file button {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}
#modal-with-file .btn-submit-with-file {
  float: right;
  margin: 20px 0;
}
#modal-with-file .modal-content {
  width: 80%;
  margin: 0 auto;
}
#modal-with-file .modal-body {
  padding: 0px 40px;
}
#custom-upload .custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  padding: 6px 25px;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #666666;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
#admin-announcement-container i.fa.fa-plus, #admin-announcement-container i.fa.fa-trash{
  float: left;
  position: relative;
  left: 25px;
  top: 3px;
}
.users-table img {
  cursor: pointer;
}
#error-container {
  background-color: #EF5350;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  display: none;
}

#error-container h6 {
  padding: 10px;
  font-size: 14px;
}
#admin-announcement-container table th {
  font-weight: 600;
  font-size: 14px;
  padding: 12px 11px;
}

#admin-announcement-container table td {
  font-size: 13.5px;
  padding: 20px 10px;
}

#admin-announcement-container .checkmark {
  left: 5px;
}

#admin-announcement-container .outlined {
  border-right: 1px solid #cccccc;
  width: 50px;
}

#admin-announcement-container table {
  border: 1px solid #cccccc;
  margin-top: 30px;
}

#admin-system-maintenance-container .table-borderless td, .table-borderless th{
  border: none !important;
}

#admin-system-maintenance-container .table-borderless {
  margin-top: 30px;
}

.button-left, .button-right {
  display: inline-block;
  padding-right: 15px;
}

.btn-announcement:hover {
  color: #08c484!important;
  background-color: #fff!important;
  border-color: #08c484!important;
}

.btn-announcement-delete:hover {
  color: #000!important;
  background-color: #fff!important;
  border-color: gray;
}

#admin-announcement-container .btn-announcement {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 230px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}
#admin-announcement-container .btn-announcement-delete{
  color: #fff;
  background-color: gray;
  border-color: gray;
  width: 230px;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 500!important;
}

#admin-system-maintenance-container table td label {
  float: right;
}
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 35px;
  background: gray;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: #fff;
}

.react-switch-checkbox:checked + .react-switch-label {
  background: #08C484;
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

#admin-system-maintenance-section {
  /* border-top: 1px solid #cccccc;
  bottom: 20%;
  margin: -10px 4.3%; */
  padding: 35px 30px;
}

.view-user-container table td {
  font-size: 13.5px;
  vertical-align: bottom!important;
}

.view-user-container table th {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 12px;
  position: sticky;
  top: 0;
  background-color: #1788C7;
  color: #fff;
  z-index: 1;
  border-top: 0px!important;
}


/* The container */
.container-check {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -15px;
  left: 10px;
  height: 22px;
  width: 22px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #ddd;
}

/* On mouse-over, add a grey background color */
.container-check:hover input + .checkmark {
  background-color: #ddd;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked + .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.checkbox-custom {
  display: none;
}

.checkbox-custom-label {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: #fff;
    border-radius: 5px;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    padding: 8px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:after {
    content: "\2714";
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    width: 20px;
    height: 20px;
    background: #2196F3;
    border-radius: 5px;
    top: 0px;
    left: 1px;
}

#admin-add-user-section .view-user-container.col {
  left: 10px;
  position: relative;
}

#admin-add-user-section .add-form-container {
  width: 100%;
}

#admin-add-user-section button {
  color: #fff;
  background-color: #08c484;
  border-color: #08c484;
  width: 150px;
  float: right;
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 0px;
}

#admin-add-user-section button:hover {
  color: #08c484!important;
  background-color: #fff!important;
  border-color: #08c484!important;
}

#admin-add-user-section .add-user-container {
  /* border-right: 1px solid #cccccc;
  padding-bottom: 40px; */
}

#admin-add-user-section {
  /* border-top: 1px solid #cccccc;
  bottom: 20%;
  margin: -10px 4.3%; */
  padding: 35px 30px;
}

#container-top {
  padding: 10px 60px;
}

#admin-control .nav-links {
  padding: 1%;
  background-color: #1788C7;
}

#admin-control .nav-logo {
  float: left;
  padding: 10px 50px;
}

#admin-control .nav-buttons {
  float: right;
  padding: 30px 50px;
  height: 60px;
}

#admin-control-login {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 65vh;
}

#admin-control-login form {
  width: 40%;
  margin: auto;
}

#admin-control-login .admin-check-remember {
  position: relative;
  margin-top: auto;
  margin-left: 0;
  margin-right: 2px;
}

#admin-control-login .form-check-label {
  color: #000!important;
}

#admin-control-login span {
  color: #1688C7;
  font-weight: 600!important;
}

#admin-control-login p {
  display: inline-block;
  float: right;
}

#admin-control-login button {
  background-color: #1788c7;
  border-color: #1788c7;
  height: 42px;
  width: 100%;
}

#admin-control-login button:hover {
  color: #1788c7!important;
  background-color: #fff!important;
  border-color: #1788c7;
}

@media (max-width: 768px){
  #admin-control-login form {
    width: 50%;
  }
}
@media (max-width: 425px){
  #admin-control-login form {
    width: 100%;
  }
}
@media (max-width: 320px){
  #admin-control-login p, #admin-control-login .form-check-label {   
    font-size: 12px;
    margin-top: 2px;
  }
}

/* by ian bobo */
#edit-admin-users-info{
  background-image: url(/images/writing.png);
  width: 21px!important;
  border-color: #fff!important;
  background-color: #fff!important;
  background-repeat: no-repeat;
  padding: 0!important;
  height: auto;
  border-radius: 0!important;
  background-position: center;
}
.dropdown{
  float: left;
  margin-right: 10px;
}
#edit-admin-users-info::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0px!important;
  border-right: 0px!important;
  border-bottom: 0!important;
  border-left: 0px!important;
}

.table-users-container {
  height: 515px;
  overflow-y: scroll;
}

table#payment-history-complete th, table#payment-history-complete td {
  padding: 0.20rem;
}